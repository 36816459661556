import React, {useEffect, useState, useCallback, useMemo, useContext} from 'react'
import {useParams} from "react-router-dom"
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {packageApi} from "../../../api";
import './tab-styles.css'
import ProposalPackage from "../../../component/package-components/proposal-package/ProposalPackage";
import {PackageContext} from "../../../component/package-components/package-parent/PackageParentPage";

export default function PackageTab({show_for_event = false, event_id}) {
    const {venue_id: _venue_id} = useParams();
    const venue_id = useMemo(() => parseInt(_venue_id), [_venue_id]);

    const {
        setPackagePopoutStatus,
        setNewPackageTitle,
        setNewPackageDescription,
        packages,
        setPackages
    } = useContext(PackageContext);

    // order change
    useEffect(() => {
        if (venue_id) {
            packageApi.listPackagesForVenue(venue_id).then(res => {
                setPackages(res || []);
            });
        }
    }, [venue_id]);

    if (show_for_event && !event_id) return <div>Something went wrong..</div>

    return (<section className='package-tab'>
        <div className='horizontal'>
            <h2>Pakker</h2>
            <SimpleButton value='Ny pakke' onClick={_ => {
                setPackagePopoutStatus('new');
                setNewPackageTitle('');
                setNewPackageDescription('');
            }}/>
        </div>
        <div className='packages'>
            {packages.sort((pk1, pk2) => pk1.proposal_order - pk2.proposal_order).map(pkg => (
                <ProposalPackage
                    pkg={pkg}
                    setPkg={updatedPkg => setPackages(prev => prev.map(p => p.id === updatedPkg.id ? updatedPkg : p))}
                />
            ))}
        </div>
    </section>)
}
