import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    doFetchEvent,
    selectEventById,
    saveLocallyUpdatedEventToBackend,
    selectLocallyUpdatedEvent,
    updateLocalEvent,
    selectLocallyUpdatedIsDirty, doDeleteEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {
    doFetchEventRoom,
    doFetchEventRooms,
    doFetchVenueRooms,
    selectEventRooms,
    selectVenueRooms
} from "../../../slice/roomSlice";
import './event-settings.css';
import InputField from "../../../component/input-field/InputField";
import {toDanishDate} from "../../../component/date_functions";
import RoomList from "../../../component/room-list/RoomList";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {doDeleteAllEventGuests} from "../../../slice/guestSlice";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import {customerApi, EventApi} from "../../../api";
import SimpleInput from "../../../component/simple-input/SimpleInput";
import NewCustomerPopup from "../../../component/customer/new-customer/NewCustomer";
import ValueKeyLabel from "../../../component/value-key-label/ValueKeyLabel";

export default function EventSettings() {
    const {event_id, venue_id} = useParams()

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const updatedEventIsDirty = useSelector(selectLocallyUpdatedIsDirty)
    const rooms = useSelector(selectEventRooms(event_id));

    const venueRooms = useSelector(selectVenueRooms(venue_id));

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [addNewRoomPopupOpen, setAddNewRoomPopupOpen] = useState(false);
    const [selectedNewRoom, setSelectedNewRoom] = useState(null);
    const [addNewRoomLoading, setAddNewRoomLoading] = useState(false);

    // customer
    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);
    const [allVenueCustomers, setAllVenueCustomers] = useState(null);
    const [creatingNewCustomer, setCreatingCustomer] = useState(false);

    const eventcustomer = useMemo(() => {
        if (!allVenueCustomers || !currentEvent) return null;
        return allVenueCustomers.find(c => c.id === currentEvent.customer_id)
    }, [currentEvent, allVenueCustomers]);

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doFetchEvent(event_id));
        dispatch(doFetchEventRooms(event_id));
        dispatch(doFetchVenueRooms(venue_id));
    }, [dispatch, event_id]);

    useEffect(() => {
        customerApi.listCustomersForVenue(venue_id).then(res => {
            setAllVenueCustomers(res)
        })
    }, [venue_id]);

    const editLocalEvent = useCallback((key, newValue) => {
        dispatch(updateLocalEvent({event_id: currentEvent.id, values: {[key]: newValue}}))
    }, []);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className={'event-settings'}>
        <CenteredPopup showPopup={addNewRoomPopupOpen} closePopup={() => setAddNewRoomPopupOpen(false)}>
            <h2>Tilføj nyt lokale</h2>
            {addNewRoomLoading && <>
                <p>Loading...</p>
                <p>
                    Det kan tage et stykke tid fra du trykker 'tilføj' til det nye lokale er oprettet.
                    Hvis det ligner det ikke virker - så giv den 30 sekunder og så refresh siden.
                </p>
            </>}
            {!addNewRoomLoading && venueRooms && <>
                <InputField
                    options={venueRooms.map(r => r.name)}
                    onlySelectableOptions
                    title='Lokale'
                    value={selectedNewRoom || ''}
                    onChanged={v => setSelectedNewRoom(v)}
                />
                <SimpleButton value='Tilføj' disabled={!selectedNewRoom} onClick={_ => {
                    const ev = venueRooms.find(vr => vr.name === selectedNewRoom)
                    setSelectedNewRoom(null);
                    setAddNewRoomLoading(true);
                    EventApi.add_room_to_event(event_id, ev.id).then(_ => {
                        dispatch(doFetchEventRooms(event_id))
                        setAddNewRoomPopupOpen(false);
                        setAddNewRoomLoading(false);
                    });
                }}/>
            </>}
        </CenteredPopup>
        <section>
            <h2>Rediger event</h2>
            <div className='horizontal'>
                <div className='event-input-fields'>
                    <SimpleInput title='Title' value={updatedEvent.title ?? ''}
                                 onChanged={v => editLocalEvent('title', v)}/>
                    <SimpleInput title='Dato' type='date' value={toDanishDate(updatedEvent.date ?? '')}
                                 onChanged={v => editLocalEvent('date', v)}/>

                    <SimpleInput title='Fra' type='time' value={updatedEvent.timestamp_from ?? ''}
                                 onChanged={v => editLocalEvent('timestamp_from', v)}/>
                    <SimpleInput title='Til' type='time' value={updatedEvent.timestamp_to ?? ''}
                                 onChanged={v => editLocalEvent('timestamp_to', v)}/>

                    {updatedEvent && updatedEvent.sale_status && <div>
                        <SimpleInput title='Salgs status'
                                     options={['Tilbud', 'Vundet', 'Tabt']}
                                     onlySelectableOptions
                                     value={{
                                         'proposal': 'Tilbud',
                                         'won': 'Vundet',
                                         'lost': 'Tabt'
                                     }[updatedEvent.sale_status]}
                                     onChanged={sale_status => {
                                         const translated = {
                                             'Tilbud': 'proposal',
                                             'Vundet': 'won',
                                             'Tabt': 'lost'
                                         }[sale_status]
                                         dispatch(updateLocalEvent({
                                             event_id: updatedEvent.id, values: {sale_status: translated}
                                         }))
                                     }}/>
                    </div>}

                    <SimpleInput title='Antal gæster' value={updatedEvent.expected_guests ?? ''}
                                 onChanged={v => editLocalEvent('expected_guests', v)}/>

                    <SimpleButton value='GEM'
                                  onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                                  disabled={!updatedEventIsDirty}
                    />
                </div>
                <div>
                    {eventcustomer && <div className=''>
                        <h2>Køber</h2>
                        <div className='horizontal center'>
                            <h4>{eventcustomer.company_name}</h4>
                        </div>
                        <ValueKeyLabel title='Kontakt'>
                            {eventcustomer.contact_name}
                        </ValueKeyLabel>
                        <ValueKeyLabel title='Telefon'>
                            {eventcustomer.contact_phone}
                        </ValueKeyLabel>
                        <ValueKeyLabel title='Email'>
                            {eventcustomer.contact_email}
                        </ValueKeyLabel>
                    </div>}
                    {!currentEvent.customer_id && <div>
                        <h4>Ingen køber på event - vælg en herunder</h4>
                        <SimpleInput
                            options={allVenueCustomers ? allVenueCustomers.map(c => c.company_name) : []}
                            value={updatedEvent.customer_id ? allVenueCustomers.find(c => c.id === updatedEvent.customer_id).company_name : ''}
                            onlySelectableOptions
                            canCreateNew
                            onChanged={name => {
                                const selected = allVenueCustomers.find(c => c.company_name === name);
                                if (selected) {
                                    dispatch(updateLocalEvent({
                                        event_id: currentEvent.id,
                                        values: {'customer_id': selected.id}
                                    }))
                                }
                            }}
                            onClickedCreateNew={_ => setCreatingCustomer(true)}
                        />
                        <SimpleButton value='GEM'
                                      onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                                      disabled={!updatedEventIsDirty}
                        />
                    </div>}
                </div>
            </div>
        </section>

        {rooms && <section className='rooms'>
            <h2>Lokaler</h2>
            <RoomList event_id={event_id} venue_id={venue_id} rooms={rooms} show_delete_btn/>
            <SimpleButton value='Tilføj lokale' onClick={_ => {
                setAddNewRoomPopupOpen(true)
            }}/>
        </section>}

        <hr/>

        <section className='danger-zone'>
            <h1>Danger zone</h1>
            <b>
                Pas på hernede. De følgende knapper kan bruges til at slette hele events eller alle gæster på eventet.
                Det kan ikke fortrydes.
            </b>

            <SimpleButton dangerous value='Slet event' onClick={_ => {
                if (window.confirm('Er du sikker på du vil slette event, seating, bordopsætning og gæster?')) {
                    dispatch(doDeleteEvent(parseInt(event_id)))
                    navigate(`/app/venue/${venue_id}/events`)
                }
            }}/>

            {currentEvent && <SimpleButton dangerous value='Slet alle gæster på event' onClick={_ => {
                if (!window.confirm(`Slet alle gæster for ${currentEvent.title}?`)) return;
                dispatch(doDeleteAllEventGuests(currentEvent.id))
            }}/>}
        </section>
        <NewCustomerPopup venue_id={venue_id} isOpen={creatingNewCustomer} createdNewCustomer={cust => {
        }} onClose={_ => setShowAddNewCustomer(false)} title='Opretter ny kunde'/>
    </div>)
}
