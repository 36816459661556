import React from "react";
import ClientEventoption from "./client-event-option/ClientEventOption";
import './client-event-package.css';


export default function ClientEventPackage({pkg, className='', setSelectedOption}) {
    return (<div key={pkg.id} className={`client-event-package ${className}`}>
        <div className='package-header'>
            <h2>{pkg.proposal_order + 1}. {pkg.title}</h2>
        </div>

        <hr />

        <div className='client-package-details'>
            <p className={`client-package-description`}>
                {pkg.description}
            </p>
        </div>
        <div className='package-options'>
            {pkg.options.filter(o => o.selected).map((opt, idx) => <ClientEventoption idx={idx} key={opt.id} opt={opt} pkg={pkg} />)}
        </div>
    </div>)
}
