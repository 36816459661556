import React, {useEffect, useMemo, useRef, useState} from "react";
import {proposalApi} from "../../../api";
import ClientEventPackage from "../../../component/package-components/client-event-package/ClientEventPackage";
import './client-proposal-page.css';
import EconomyTable from "../../../component/package-components/economy-table/EconomyTable";
import {useNavigate, useParams} from "react-router-dom";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import html2pdf from "html2pdf.js";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import SimpleCheckbox from "../../../component/simple-checkbox/SimpleCheckbox";
import documentIcon from '../../../assets/document.svg';

export default function ClientProposalPage({_token=null, propProposalRef=null}) {
    const {proposalToken: urlToken} = useParams();

    const proposalToken = useMemo(() => _token || urlToken, [_token, urlToken]);

    const [packages, setPackages] = useState(null);
    const [proposal, setProposal] = useState(null);
    const [event, setEvent] = useState(null);
    const [customer, setCustomer] = useState(null);

    const _myProposalRef = useRef(null);

    const proposalRef = useMemo(() => propProposalRef || _myProposalRef, [propProposalRef]);

    const [generatedPdf, setGeneratedPdf] = useState(null);
    const [generatedPdfSignedUrl, setGeneratedPdfSignedUrl] = useState(null);
    const [showSignPopup, setShowSignPopup] = useState(false);

    const [theyAreWhoTheySay, setTheyAreWhoTheySay] = useState(false);
    const [theyAcceptTerms, setTheyAcceptTerms] = useState(false);

    const generatePdfAndDisplay = () => {
        const element = proposalRef.current;
        // Add print class to make some custom styling
        element.classList.add('printable-wrapper');
        const options = {
            margin: 0.2,
            filename: 'proposal.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'a4', orientation: 'portrait'},
        };

        // Instead of saving the PDF, we output a data URI string
        html2pdf().set(options).from(element).output('datauristring').then((pdfDataUri) => {
            // Open a popup or new window and embed the PDF in an iframe
            setGeneratedPdf(pdfDataUri);
            proposalApi.uploadProposalPdf(proposal.id, pdfDataUri).then(res => {
                console.log("Uploaded PDF:", res);
                setGeneratedPdfSignedUrl(res);
            });
        })
            .catch((err) => {
                console.error("Error generating PDF:", err);
            })
            .finally(() => {
                element.classList.remove('printable-wrapper');
            });
    };

    useEffect(() => {
        if (!proposalToken) {
            return;
        }
        proposalApi.viewProposalUsingtoken(proposalToken).then(res => {
            const {proposal, event, customer, packages} = res;
            setProposal(proposal);
            setEvent(event);
            setCustomer(customer);
            setPackages(packages);
        });
    }, [proposalToken]);

    if (!packages || !proposal || !event || !customer) {
        return null;
    }

    return (<div className='client-proposal-page'>
        <main ref={proposalRef} className='printable-wrapper'>
            <h1 className='proposal-client-h1'><img src={documentIcon} />{proposal.name}</h1>

            {packages && packages.sort((a, b) => a.proposal_order-b.proposal_order).filter(e => e.type !== 'economy').map(p =>
                <ClientEventPackage key={p.id} pkg={p}/>)}
            <h2>Økonomi</h2>
            <EconomyTable packages={packages} expected_guests={event.expected_guests}/>
        </main>
        <div className='accept-tools horizontal'>
            <div className='grower'/>
            <SimpleButton value='Accepter' onClick={_ => {
                generatePdfAndDisplay();
                setShowSignPopup(true);
            }}/>
        </div>
        <CenteredPopup className='final-accept-popup' showPopup={showSignPopup} closePopup={_ => {
            setShowSignPopup(false);
            setTheyAcceptTerms(false);
            setTheyAreWhoTheySay(false);
            setGeneratedPdfSignedUrl(null);
        }}>
            <h1>Underskriver aftale:</h1>
            {generatedPdf && <iframe
                src={generatedPdfSignedUrl}>
            </iframe>}
            <div className='terms-and-conditions'>
                <div>
                    <SimpleCheckbox
                        title={`Jeg erklærer på tro og love at jeg er ${customer.contact_name} og har ret til at indgå aftalen.`}
                        checked={theyAreWhoTheySay}
                        onChange={_ => {
                            setTheyAreWhoTheySay(!theyAreWhoTheySay);
                        }}
                    />
                    <SimpleCheckbox
                        title='Jeg accepterer betingelserne og vil gerne underskrive aftalen.'
                        checked={theyAcceptTerms}
                        disabled={!theyAreWhoTheySay}
                        onChange={_ => {
                            setTheyAcceptTerms(!theyAcceptTerms);
                        }}
                    />
                </div>
                <SimpleButton
                    disabled={!theyAreWhoTheySay || !theyAcceptTerms}
                    value='Underskriv'
                    onClick={_ => {
                        if (!theyAreWhoTheySay || !theyAcceptTerms) {
                            return;
                        }
                        proposalApi.signProposal(proposal.id, generatedPdf).then(res => {
                            console.log("Proposal signed:", res);
                            setShowSignPopup(false);
                        });
                    }}/>
            </div>
        </CenteredPopup>
    </div>);
}
