import React, {useCallback, useEffect, useState} from "react";
import './event-schedule-page.css';
import {SharedMessageApi} from "../../../api";
import {useParams} from "react-router-dom";
import Sektion from "../../../component/sektion-components/sektion/sektion";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import SharedMessage from "../../../component/shared-message/SharedMessage";
import ItineraryTimeplan from "../../../component/itinerary-timeplan/ItineraryTimeplan";

export default function EventSchedulePage({no_edit = false}) {
    let {event_id, venue_id} = useParams();

    const [sektioner, setSektioner] = useState(null);

    const addSektion = useCallback(() => {
        if (!sektioner) return;

        const section_name = `${event_id}_section-${sektioner.length + 1}`;
        SharedMessageApi.create_or_update(section_name, event_id, venue_id, ';;').then(res => {
            setSektioner(prev => ([...prev, res]));
        });
    }, [setSektioner, sektioner, event_id, venue_id]);

    useEffect(() => {
        SharedMessageApi.list_event_sections(event_id).then(res => {
            setSektioner(res);
        });
    }, [event_id]);

    if (!sektioner) return <div>Loader køreplan...</div>;

    return (<div className='event-schedule-page'>
        <section>
            <SharedMessage event_id={event_id} venue_id={venue_id}
                           message_id={`${event_id}_itinerary_generelt`}
                           message_title='Generelle køreplans noter'
                           no_edit={no_edit}
                           message_description={'Generel information om arrangementet'}/>
        </section>
        <ItineraryTimeplan event_id={event_id} no_edit={no_edit} />

        {(!no_edit || sektioner.length > 0) && <section className='sections'>
            <div>
                <h2>Sektioner</h2>
                <p className='sektion-intro'>
                    Ændringer bliver automatisk gemt.
                </p>
            </div>
            {sektioner.map(sektion => <div key={sektion.id} className='sektion'>
                <Sektion sektion={sektion} display_status/>
                {!no_edit && <div className='horizontal'>
                    <div className='grower'>&nbsp;</div>
                    <SimpleButton red value='Slet' onClick={() => {
                        SharedMessageApi.delete_event_message(event_id, sektion.id).then(() => {
                            setSektioner(prev => prev.filter(s => s.id !== sektion.id));
                        });
                    }}/>
                </div>}
            </div>)}
            {!no_edit && <SimpleButton
                onClick={addSektion} value="Tilføj sektion"/>}
        </section>}
    </div>)
}
