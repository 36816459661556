import React, {useEffect, useState} from "react";
import SimpleInput from "../../component/simple-input/SimpleInput";
import {hostApi} from "../../api";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useParams} from "react-router-dom";
import './host-venue-page.css';


export default function HostVenuePage() {
    const {venue_id} = useParams();

    const [masterHostName, setMasterHostName] = useState("");
    const [masterHostPassword, setMasterHostPassword] = useState("");

    const [venueHost, setVenueHost] = useState({
        name: "",
        password: "",
    });

    useEffect(() => {
        if (!venue_id) return;
        hostApi.getVenueHost(venue_id).then(r => {
            if (!r) {
                setMasterHostName('');
                setMasterHostPassword('');
                return;
            }
            setVenueHost(r);
            setMasterHostName(r.name);
            setMasterHostPassword(r.password);
        });
    }, [venue_id]);

    return (
        <div className='host-venue-page'>
            <h1>Vært admin</h1>
            <p>
                Hvis du bruger værter til at velkomme dine gæster, kan du her sætte brugernavn og password for værten.

                Alle værter bruger samme login. Efter login kan værten vælge hvilket event de vil arbejde på, hvilken
                dør de står i og hvad deres navn er.
            </p>
            <div className='horizontal center'>
                <SimpleInput
                    title='Vært Login Navn'
                    value={masterHostName}
                    onChanged={setMasterHostName}
                    name='host-name'
                />
                <SimpleInput
                    title='Vært Password'
                    value={masterHostPassword}
                    onChanged={setMasterHostPassword}
                    type={masterHostPassword ? 'password' : 'text'}
                    name='host-password'
                />
                <SimpleButton
                    value='Gem'
                    onClick={_ => {
                        hostApi.createOrUpdate(venue_id, masterHostName, masterHostPassword).then(setVenueHost);
                    }}
                    disabled={masterHostName === venueHost.name && masterHostPassword === venueHost.password}
                />
            </div>
        </div>
    )
}
