import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {
    doCreateEventUser,
    doFetchEvent,
    doListUsersByEvent,
    saveLocallyUpdatedEventToBackend,
    selectEventById,
    selectLocallyUpdatedEvent, selectLocallyUpdatedIsDirty,
    selectUsersByEventId,
    updateLocalEvent,
} from "../../../slice/eventSlice";
import {doGetUser} from "../../../slice/userSlice";
import {prettyTimeDifference} from "../../../component/date_functions";
import './event-client-tab.css'
import InputField from "../../../component/input-field/InputField";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {adminAPI, customerApi, VenueAPI} from "../../../api";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import SimpleInput from "../../../component/simple-input/SimpleInput";
import SingleCharButton from "../../../component/single-char-button/SingleCharButton";
import ValueKeyLabel from "../../../component/value-key-label/ValueKeyLabel";
import NewCustomerPopup from "../../../component/customer/new-customer/NewCustomer";
import NewUser from "../../../component/new-user/NewUser";

export default function EventClientTab() {
    const {event_id, venue_id} = useParams()

    const [newPassword, setNewPassword] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('');
    const [settingNewPasswordUser, setSettingNewPasswordUser] = useState(null);

    // new customer
    const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);
    const [allVenueClients, setAllVenueClients] = useState(null);
    const [selectedUserToAdd, setSelectedUserToAdd] = useState('');

    // new customer
    const [allVenueCustomers, setAllVenueCustomers] = useState(null);

    const currentEvent = useSelector(selectEventById(event_id))
    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const users = useSelector(selectUsersByEventId(event_id))
    const updatedEventIsDirty = useSelector(selectLocallyUpdatedIsDirty)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doListUsersByEvent(event_id));
        dispatch(doFetchEvent(event_id));
    }, [dispatch, event_id]);

    useEffect(() => {
        if (!showAddNewCustomer) return;
        VenueAPI.list_all_venue_clients(venue_id).then(setAllVenueClients)
    }, [showAddNewCustomer, venue_id]);

    const createEventUser = useCallback((values) => {
        dispatch(doCreateEventUser({
            ...values, event_id: currentEvent.id
        }))
    }, [dispatch, currentEvent]);

    if (!currentEvent || !updatedEvent) {
        return <div>Loading...</div>
    }

    return (<div className='event-client-tab'>
        {settingNewPasswordUser &&
            <CenteredPopup showPopup={passwordStatus === 'open'} closePopup={_ => setPasswordStatus('')}>
                <h3>Resetting password for {settingNewPasswordUser.name}</h3>
                <br/>
                <InputField value={newPassword} onChanged={setNewPassword} title='password'/>
                <br/>
                {passwordStatus === 'OK' && <p>Updated password.</p>}
                <SimpleButton loading={passwordStatus === 'loading'} disabled={newPassword.length <= 3}
                              value='Submit' onClick={_ => {
                    setPasswordStatus('loading')
                    adminAPI.patch_user(settingNewPasswordUser.id, newPassword).then(_ => setPasswordStatus('OK'))
                }}/>
            </CenteredPopup>
        }
        <section>
            <div className='customer-wrapper'>
                {users.filter(u => u.role === 'venue-client').map(user => <div key={user.id} className='customer'>
                    <div className='name-wrapper'>
                        <h2>{user.name}</h2>
                        <SingleCharButton
                            char='?'
                            options={['Sæt nyt password', 'fjern bruger fra event']}
                            onClick={sel => {
                                if (sel === 'Sæt nyt password') {
                                    setSettingNewPasswordUser(user);
                                    setPasswordStatus('open');
                                } else if (sel === 'fjern bruger fra event') {
                                    if (window.confirm('Fjern bruger fra event?')) {
                                        adminAPI.remove_user_from_event(user.id, event_id).then(_ => {
                                            dispatch(doListUsersByEvent(event_id));
                                        })
                                    }
                                }
                            }}
                        />
                    </div>
                    <p>{user.email}</p>
                    <p>{user.phone}</p>
                    <p>{user.timestamp_seated ? `Seated: ${prettyTimeDifference(new Date(), new Date(user.timestamp_seated))}` : 'Har aldrig seated'}</p>
                    <p>{user.login_timestamp ? `Sidste login: ${prettyTimeDifference(new Date(), new Date(user.login_timestamp))}` : 'Har aldrig logget ind'}</p>
                </div>)}
            </div>
            <SimpleButton value='Tilføj ny kunde' onClick={_ => setShowAddNewCustomer(true)}/>
        </section>

        <section className='event-note'>
            <h3>Velkomst besked til din kunde, når de logger ind:</h3>
            <textarea value={updatedEvent.note_to_client ?? ''}
                      onChange={v => dispatch(updateLocalEvent({
                          event_id: updatedEvent.id,
                          values: {'note_to_client': v.target.value}
                      }))}/>
            <SimpleButton value='Save' onClick={_ => dispatch(saveLocallyUpdatedEventToBackend)}
                          disabled={updatedEvent.note_to_client === currentEvent.note_to_client}/>
        </section>
        <CenteredPopup showPopup={showAddNewCustomer} closePopup={setShowAddNewCustomer}>
            <div className='add-user-to-event-popup-wrapper'>
                <NewUser title='Ny kunde' submit={createEventUser}/>
                <div className='add existing'>
                    <h2>Eller tilføj eksisterende</h2>
                    {allVenueClients && <InputField options={allVenueClients.map(e => e.email)}
                                                    value={selectedUserToAdd ? selectedUserToAdd.name : ''}
                                                    onChanged={email => setSelectedUserToAdd(allVenueClients.find(c => c.email === email))}
                                                    onlySelectableOptions/>}
                    <SimpleButton value='Tilføj bruger' disabled={!selectedUserToAdd} onClick={_ => {
                        adminAPI.add_user_to_event(selectedUserToAdd.id, event_id).then(_ => {
                            dispatch(doListUsersByEvent(event_id));
                        })
                    }}/>
                </div>
            </div>
        </CenteredPopup>
    </div>)
}
