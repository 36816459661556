import React, {createContext, useCallback, useState} from "react";
import SidePopout from "../../side-popout/SidePopout";
import cross from "../../../assets/clear-24px.svg";
import SimpleInput from "../../simple-input/SimpleInput";
import SimpleTextarea from "../../simple-input/simple-textarea";
import SimpleButton from "../../simple-button/SimpleButton";
import {packageApi} from "../../../api";
import NewPackagePopout from "../new-package-popout/NewPackagePopout";
import {useParams} from "react-router-dom";
import close from "../../../assets/clear-24px.svg";

export const PackageContext = createContext(null);

export default function PackageParentPage({children}) {
    const {event_id, venue_id} = useParams();

    const [editingOption, setEditingOption] = useState(null);
    const [editingPackage, setEditingPackage] = useState(null);

    const [packagePopoutStatus, setPackagePopoutStatus] = useState('closed');
    const [newPackageTitle, setNewPackageTitle] = useState('');
    const [newPackageDescription, setNewPackageDescription] = useState('');
    const [newPackagePriceType, setNewPackagePriceType] = useState('Pax');

    const [dragging, setDragging] = useState(null);
    const [draggedOver, setDraggedOver] = useState(null);

    const [packages, setPackages] = useState([]);

    const [activeProposal, setActiveProposal] = useState(null);

    // Create a new option for a specific package
    const saveEditingOption = useCallback(() => {
        const name = editingOption.name
        const description = editingOption.description
        const netto_amount = editingOption.netto_amount
        const total_amount = editingOption.total_amount

        const pkgId = editingOption.package_id
        packageApi
            .updateOption(editingOption.package_id, editingOption.id, {
                name, description, netto_amount, total_amount
            })
            .then(o => {
                setPackages(prev => prev.map(p => {
                    if (p.id === pkgId) {
                        return {...p, options: p.options.map(opt => opt.id === o.id ? o : opt)}
                    }
                    return p
                }))
                setEditingOption(null)
            })
    }, [editingOption]);

    // Handle changes to new option fields for a given package
    const handleEditOption = useCallback((field, value) => {
        setEditingOption(prev => {
            if (!prev) return null;

            return ({...prev, [field]: value})
        })
    }, []);

    // Create a new option for a specific package
    const createNewOptionForPackage = useCallback((pkgId) => {
        packageApi.createOptionForPackage(pkgId, '', '', 0, 0).then(opt => {
            setPackages(prev => prev.map(p => {
                if (p.id === pkgId) {
                    return {...p, options: [...p.options, opt]}
                }
                return p
            }))
        })
    }, [setPackages]);

    return <PackageContext.Provider value={{
        editingOption, setEditingOption,

        editingPackage, setEditingPackage,

        packagePopoutStatus, setPackagePopoutStatus,

        newPackageTitle, setNewPackageTitle,

        newPackageDescription, setNewPackageDescription,

        newPackagePriceType, setNewPackagePriceType,

        dragging, setDragging,

        draggedOver, setDraggedOver,

        packages, setPackages,

        activeProposal, setActiveProposal
    }}>
        {children}
        {packagePopoutStatus === 'edit' && <SidePopout className='popout'>
            <div className='horizontal'>
                <h3>Redigerer pakke</h3>
                <img className='close-popout'
                     src={cross}
                     alt={'close'} onClick={_ => setPackagePopoutStatus('closed')}/>
            </div>
            <SimpleInput
                title='Titel'
                type="text"
                value={newPackageTitle}
                onChanged={e => setNewPackageTitle(e)}
            />
            <SimpleTextarea
                title='Beskrivelse'
                type="text"
                value={newPackageDescription}
                onChanged={e => setNewPackageDescription(e)}
            />

            <SimpleInput
                options={['Pax', 'Flad rate']}
                title='Prisstruktur'
                disabled={activeProposal.locked}
                onlySelectableOptions
                onChanged={e => setNewPackagePriceType({
                    'Pax': 'px', 'Flad rate': 'flat',
                }[e])}
                value={{
                    'px': 'Pax', 'flat': 'Flad rate'
                }[newPackagePriceType]}
            />

            <SimpleButton onClick={_ => packageApi.updatePackage(editingPackage.id, {
                title: newPackageTitle, description: newPackageDescription, price_type: newPackagePriceType
            }).then(pkg => {
                setPackages(prev => prev.map(p => p.id === pkg.id ? pkg : p))
                setPackagePopoutStatus('closed')
            })} value="Gem"/>
            <SimpleButton onClick={_ => packageApi.deletePackage(editingPackage.id).then(_ => {
                setPackages(prev => prev.filter(p => p.id !== editingPackage.id))
                setPackagePopoutStatus('closed')
            })} value="Slet"/>
            <SimpleButton value='Tilføj mulighed' onClick={_ => createNewOptionForPackage(editingPackage.id)}/>
        </SidePopout>}

        {packagePopoutStatus === 'new' && <NewPackagePopout
            venue_id={venue_id}
            proposal_id={activeProposal.id}
            event_id={event_id}
            createdNewPackage={p => setPackages(prev => [...prev, p])}
            onClose={_ => setPackagePopoutStatus('closed')}/>}

        {editingOption !== null && <SidePopout className='popout'>
            <div className='horizontal'>
                <h3>Redigerer mulighed</h3>
                <img className='close-popout'
                     src={cross}
                     alt={'close'} onClick={_ => setEditingOption(null)}/>
            </div>
            <SimpleInput
                title="Navn"
                value={editingOption.name || ''}
                onChanged={value => handleEditOption('name', value)}
            />
            <SimpleTextarea
                title="Beskrivelse"
                value={editingOption.description || ''}
                onChanged={value => handleEditOption('description', value)}
            />
            <SimpleInput
                title="Netto beløb"
                type="number"
                value={editingOption.netto_amount || ''}
                onChanged={value => handleEditOption('netto_amount', value)}
            />
            <SimpleInput
                title="Total"
                type="number"
                value={editingOption.total_amount || ''}
                onChanged={value => handleEditOption('total_amount', value)}
            />
            <SimpleButton
                onClick={saveEditingOption}
                value="Gem"
            />
            <SimpleButton
                onClick={_ => {
                    packageApi.deleteOption(editingOption.package_id, editingOption.id).then(_ => {
                        setPackages(prev => prev.map(p => {
                            if (p.id === editingOption.package_id) {
                                return {...p, options: p.options.filter(o => o.id !== editingOption.id)}
                            }
                            return p
                        }))
                        setEditingOption(null)
                    })
                }}
                value="Slet"
            />
        </SidePopout>}
    </PackageContext.Provider>
}
