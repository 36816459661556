import React from 'react'
import {Outlet} from "react-router-dom";
import './venue-page.css'
import PackageParentPage from "../../../component/package-components/package-parent/PackageParentPage";

export default function VenuePage() {
    return (<div className={'venue-page'}>
        <PackageParentPage>
            <Outlet/>
        </PackageParentPage>
    </div>)
}
