import React, {useEffect, useMemo, useState} from 'react'
import './venue-client-menu-wrapper.css'
import {useDispatch, useSelector} from "react-redux";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {
    doGetUser,
    doGetUserEvents,
    selectAllUserEvents,
    selectSelectedEvent,
    selectUser,
    setSelectedEvent
} from "../../../slice/userSlice";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import InputField from "../../../component/input-field/InputField";

export default function VenueClientMenuWrapper() {
    const selectedEvent = useSelector(selectSelectedEvent);

    const user = useSelector(selectUser)
    const allEvents = useSelector(selectAllUserEvents)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(doGetUser())
        dispatch(doGetUserEvents())
    }, [dispatch]);

    const navigate = useNavigate()

    useEffect(() => {
        if (!allEvents || !allEvents.length) return;
        dispatch(setSelectedEvent(allEvents[0].id))
    }, [allEvents]);

    function urlContains(s) {
        return window.location.toString().toLowerCase().endsWith(s)
    }

    useEffect(() => {
        if (Object.keys(user).length === 0) return;
        if (['developer', 'venue-owner'].indexOf(user.role) !== -1) {
            navigate(`/app/venue`)
        }
    }, [user, navigate]);

    useEffect(() => {
        if (!selectedEvent) return;
        if (selectedEvent.sale_status !== 'tilbud') return;
        navigate('/proposal')
    }, [selectedEvent]);

    return (<div className='home-page-menu-wrapper'>
        <div className='left-menu'>
            {selectedEvent && allEvents.length > 1 && <InputField
                title='Hvilket event er du interesseret i?'
                onlySelectableOptions
                options={allEvents.map(e => e.title)}
                value={selectedEvent.title}
                onChanged={selectedTitle => dispatch(setSelectedEvent(allEvents.find(e => e.title === selectedTitle).id))}/>}
            {selectedEvent && <h2 className='event-title'>{selectedEvent.title}</h2>}
            <Link className={window.location.pathname === '/app' ? 'selected' : ''} to='/app'>Home</Link>
            <Link className={urlContains('/guest') ? 'selected' : ''} to='/app/guest'>Håndter gæster</Link>
            <Link className={urlContains('/seating') ? 'selected' : ''} to='/app/seating'>Seating</Link>
            <Link className={urlContains('/export') ? 'selected' : ''} to='/app/export'>Export</Link>
            <Link className={urlContains('/proposal') ? 'selected' : ''} to='/app/proposal'>Tilbud</Link>
            <div className='grower'/>
            <SimpleButton value='logout' onClick={_ => navigate('/logout')}/>
        </div>

        <div className='scrollable' id={'venue-client-scrollable'}>
            <Outlet/>
        </div>
    </div>)
}
