import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './waiter-list.css'
import {exportAPI} from "../../../api";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    doFetchStationAssignments, doInsertOrUpdateAssignmentBatch,
    selectAllStationAssignments, selectLocalStationChanges, setLocalChanges
} from "../../../slice/stationSlice";
import debounce from "lodash.debounce";

export default function WaiterList() {
    const [waiterList, setWaiterList] = useState(null);

    const {event_id, venue_id} = useParams()
    const dispatch = useDispatch()
    const assignments = useSelector(selectAllStationAssignments)
    const uncommittedChanges = useSelector(selectLocalStationChanges)

    const trRefs = useRef({})

    useEffect(() => {
        if (!event_id) return;
        exportAPI.export_waiter_list_by_event_id(event_id).then(setWaiterList)
        dispatch(doFetchStationAssignments(event_id))
    }, [event_id])

    const groups = useMemo(() => {
        if (!waiterList) return []

        const allGroupNames = waiterList.map(wl => (wl.table_name - wl.table_name % 100) / 100)
        const uniqueGroupNames = allGroupNames.filter((t, idx) => allGroupNames.indexOf(t) === idx)

        return uniqueGroupNames.map(groupName => {
            const tables = waiterList.filter(wl => ((wl.table_name - wl.table_name % 100) / 100) === groupName)

            const maxSeats = tables.map(t => t.max_seating).reduce((prev, curr) => prev + curr, 0)

            const seated = tables.map((t, idx) => t.seated_count).reduce((acc, curr) => acc + curr, 0)

            return {
                groupName,
                maxSeats,
                seated,
                tables
            }
        })
    }, [waiterList]);

    const totalTableCount = useMemo(() => {
        return groups.reduce((prev, curr) => prev + curr.tables.length, 0)
    }, [groups])

    // Selection handling
    const [selectedStation, setSelectedStation] = useState(null);
    const [multiInput, setMultiInput] = useState([]);
    const [dragSelectionGuestCount, setDragSelectionGuestCount] = useState(0);
    const [dragSelectionTableNames, setDragSelectionTableNames] = useState([]);

    const stationClicked = useCallback((elem) => {
        elem = elem.toString()
        if (selectedStation && selectedStation === elem) {
            setSelectedStation(null)
            setDragSelectionGuestCount(0);
            setDragSelectionTableNames([])
            setMultiInput([])
        } else {
            setSelectedStation(elem)
        }
    }, [setSelectedStation, selectedStation]);

    // Stop selection handling

    function getTrFromNode(node) {
        if (!node) return false
        if (node.nodeName !== 'TR') {
            return getTrFromNode(node.parentElement)
        }
        return node
    }

    const getTableIdsFromSelection = useCallback(() => {
        const selected = window.getSelection();
        if (selected.type !== 'Range') return false;

        const row1 = getTrFromNode(selected.focusNode)
        const row2 = getTrFromNode(selected.anchorNode)

        if (!row1 || !row2) return false;

        const id1 = parseInt(row1.id.split('-')[0])
        const id2 = parseInt(row2.id.split('-')[0])

        if (isNaN(id1) || isNaN(id2)) return false;

        const from = Math.min(id1, id2)
        const to = Math.max(id1, id2)

        return {from, to, row1, row2}
    }, []);

    const getTotalSelectedFromSelection = useCallback(() => {
        if (!waiterList) return;

        const res = getTableIdsFromSelection()
        if (!res) return;
        const {from, to} = res

        let total = 0
        const tNames = []
        waiterList.map(wl => {
            const tabNr = parseInt(wl.table_name)
            if (from <= tabNr && tabNr <= to) {
                total += wl.seated_count
                tNames.push(wl.table_name)
            }
        })
        setDragSelectionGuestCount(total)
        setDragSelectionTableNames(tNames)
    }, [waiterList]);

    const mouseMove = useCallback(e => {
        if (selectedStation || e.buttons === 0) return;
        getTotalSelectedFromSelection()
    }, [getTotalSelectedFromSelection])

    const mouseUp = useCallback((e) => {
        const selected = window.getSelection();
        if (selected.type !== 'Range') return;
        const res = getTableIdsFromSelection()
        if (!res) return;
        const {from, to, row1} = res

        setMultiInput(Array((to - from) + 1).fill(0).map((i, idx) => `${from + idx}-id`))
        getTotalSelectedFromSelection()

        stationClicked(row1.id)
    }, [stationClicked]);

    // Change handling
    const updateStationBackend = useCallback((event_id, updated) => {
        dispatch(doInsertOrUpdateAssignmentBatch({event_id, updated}))
    }, [dispatch]);

    const debouncePatchStation = useMemo(() => debounce(updateStationBackend, 800), [updateStationBackend]);

    useEffect(() => {
        if (Object.keys(uncommittedChanges).length === 0 || !event_id) return;
        debouncePatchStation(event_id, uncommittedChanges)
    }, [uncommittedChanges, debouncePatchStation, event_id])

    const updateStation = useCallback((station_identifier, event_id, assigned) => {
        const multChanges = {}
        multiInput.forEach(mi => {
            multChanges[mi] = assigned
        })
        dispatch(setLocalChanges({[station_identifier]: assigned, ...multChanges}))
    }, [dispatch, multiInput]);

    // Stop change handling

    const waiterGuestTableCount = useMemo(() => {
        if (!waiterList || !assignments) return;
        const waiterTableCounts = {}
        let currWaiter = null;
        let currCount = 0;
        let prevTable = null;

        waiterList.forEach(table => {
            const tableId = `${table.table_name}-id`
            const assignedWaiter = assignments[tableId]

            if (assignedWaiter !== currWaiter) {
                if (currWaiter) {
                    waiterTableCounts[prevTable] = currCount
                }
                currWaiter = assignedWaiter
                currCount = parseInt(table.seated_count)
                prevTable = tableId
            } else {
                currCount += parseInt(table.seated_count)
                prevTable = tableId
            }
        })
        return waiterTableCounts
    }, [assignments, waiterList]);

    return (<div className='waiter-list-page' onMouseUp={mouseUp} onMouseMove={mouseMove}>
        {window.location.href.endsWith('print' ? null :
            <a href={`/venue/${venue_id}/event/${event_id}/waiter-export/print`} className='export-link'>Export</a>)}
        <b className='hint-to-export'>Når du eksporterer så tryk ctrl+p (cmd+p) på mac. Derefter gå ned i skalér og vælg 50%</b>
        <div className='waiter-list'>
            {groups.map(({groupName, maxSeats, seated, tables}) => {
                const groupId = `${groupName}-group-title`
                return (<table key={groupId}>
                    <tbody>
                    {[
                        <tr key={groupName} id={groupId}
                            ref={el => trRefs.current[groupId] = el}
                            className='group-header'>
                            <td>{groupName * 100}</td>
                            <td>Max</td>
                            <td>Antal</td>
                            {selectedStation !== groupId.toString() && <td className='waiter-field'
                                                                           onClick={e => stationClicked(groupId)}>{uncommittedChanges[groupId] ?? assignments[groupId] ?? ''}</td>}
                            {selectedStation === groupId.toString() && <td className='waiter-field'>
                                <input
                                    onBlur={e => stationClicked(groupId)}
                                    autoFocus={true}
                                    value={uncommittedChanges[groupId] ?? assignments[groupId] ?? ''}
                                    onChange={e => {
                                        updateStation(groupId, event_id, e.target.value)
                                    }}/>
                            </td>}
                            <td>info</td>
                            <td>waiter count</td>
                        </tr>,
                        ...tables.map(t => {
                            const tableId = `${t.table_name}-id`
                            const dragSelectionIndex = dragSelectionTableNames.indexOf(t.table_name)

                            const shouldDisplayDragCount = !selectedStation && dragSelectionIndex >= 0 && dragSelectionIndex === dragSelectionTableNames.length - 1

                            return (<tr
                                key={tableId}
                                id={tableId}
                                className={`waiter-input-field ${dragSelectionIndex !== -1 ? 'drag-selected' : ''}`}
                                ref={el => trRefs.current[tableId] = el}>
                                <td>{t.table_name}</td>
                                <td>{t.max_seating}</td>
                                <td className='count-td'>{t.seated_count ?? '0'}</td>
                                {selectedStation !== tableId && <td className='waiter-field'
                                                                    onClick={e => stationClicked(tableId)}>
                                    {shouldDisplayDragCount && <b>{dragSelectionGuestCount}</b>}
                                    {!shouldDisplayDragCount && (uncommittedChanges[tableId] ?? assignments[tableId] ?? ' ')}
                                </td>}
                                {selectedStation === tableId && <td className='waiter-field'>
                                    <input
                                        onBlur={e => stationClicked(tableId)}
                                        autoFocus={true}
                                        value={uncommittedChanges[tableId] ?? assignments[tableId] ?? ''}
                                        onKeyUp={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                stationClicked(tableId)
                                            }
                                        }}
                                        onChange={e => {
                                            updateStation(tableId, event_id, e.target.value)
                                        }}/>
                                    {dragSelectionGuestCount > 0 &&
                                        <label><br/>(<b>{dragSelectionGuestCount}</b> guests)</label>}
                                </td>}
                                <td>{t.dietary && <b>🍴</b>}</td>
                                <td>{waiterGuestTableCount[tableId] ?? ''}</td>
                            </tr>)
                        }),
                        <tr key={`${groupName}-end`} className='group-end'>
                            <td>total</td>
                            <td>{maxSeats}</td>
                            <td>{seated}</td>
                            <td></td>
                            <td></td>
                        </tr>,
                        <tr key={`${groupName}-filler`}>
                            <td colSpan={4} className='filler'>&nbsp;</td>
                        </tr>
                    ]}
                    </tbody>
                </table>)
            })}
            <div className='opsummering'>
                <table>
                    <thead>
                    <tr>
                        <td>Etage</td>
                        <td>Antal</td>
                    </tr>
                    </thead>
                    <tbody>
                    {groups.map(({groupName, seated}) => {
                        return <tr key={groupName}>
                            <td>{groupName * 100}</td>
                            <td>{seated}</td>
                        </tr>
                    })}
                    <tr>
                        <td>Total</td>
                        <td>{groups.reduce((curr, group) => curr + group.seated, 0)}</td>
                    </tr>
                    </tbody>
                </table>
                <div className='dishwash'>
                    <h4>OPVASK</h4>
                    <p>Dansgolvsröj:</p>
                    <input type='text' value={uncommittedChanges['dishwash-1-id'] ?? assignments['dishwash-1-id'] ?? ''}
                            onChange={e => updateStation('dishwash-1-id', event_id, e.target.value)}/>
                    <p>30 min senare:</p>
                    <input type='text' value={uncommittedChanges['dishwash-2-id'] ?? assignments['dishwash-2-id'] ?? ''}
                            onChange={e => updateStation('dishwash-2-id', event_id, e.target.value)}/>
                    <p>1h senare:</p>
                    <input type='text' value={uncommittedChanges['dishwash-3-id'] ?? assignments['dishwash-3-id'] ?? ''}
                            onChange={e => updateStation('dishwash-3-id', event_id, e.target.value)}/>
                    <h4>LUKKE VAGT</h4>
                    <input type='text' value={uncommittedChanges['close-1-id'] ?? assignments['close-1-id'] ?? ''}
                            onChange={e => updateStation('close-1-id', event_id, e.target.value)}/>
                </div>
            </div>
            <div className='waiter-guest-count'>
            </div>
        </div>
    </div>)
}
