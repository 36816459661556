import React, {useEffect, useMemo, useState} from 'react';
import './calendar.css';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import arrDown from '../../assets/arr_down.png';

export default function Calendar({venue, events, showCreateNewEvent}) {
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();
    searchParams = Object.fromEntries(searchParams.entries());
    const {month, year} = searchParams;

    const {event_id} = useParams();

    const eventsByDate = useMemo(() => {
        const res = {}
        events.filter(e => e.sale_status !== 'lost').forEach(e => {
            const d = new Date(e.date)
            if (res[d] === undefined) {
                res[d] = [];
            }
            res[d].push(e)
        })
        Object.entries(res).forEach(([k, v]) => {
            res[k] = v.sort((a, b) => {
                if (a.timestamp_from === null && b.timestamp_from === null) return 0;
                if (a.timestamp_from === null) return -1;
                if (b.timestamp_from === null) return 1;
                if (a.timestamp_from < b.timestamp_from) return -1;
                if (a.timestamp_from > b.timestamp_from) return 1;
                return 0;
            })
        })
        return res
    }, [events]);

    const [currentDate, setCurrentDate] = useState(null);

    const prevMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const nextMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    useEffect(() => {
        const currentUrlYear = parseInt(year);
        const currentUrlMonth = parseInt(month);

        if (currentDate === null) {
            if (!currentUrlYear || isNaN(currentUrlMonth)) {
                const d = new Date()
                d.setDate(1)
                setCurrentDate(d);
                return;
            }

            const d = new Date(currentUrlYear, currentUrlMonth - 1, 1);
            setCurrentDate(d);
            return;
        }

        const selectedYear = currentDate.getFullYear();
        const selectedMonth = currentDate.getMonth();

        if (currentUrlYear === selectedYear && currentUrlMonth === selectedMonth + 1) return; // Adjust for comparison
        setSearchParams({
            year: selectedYear,
            month: selectedMonth + 1, // Convert back to one-based month for URL
        });
    }, [event_id, currentDate, year, month]);

    const {daysOfWeek, weeks} = useMemo(() => {
        if (!currentDate) return {}

        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Adjust the start of the calendar to the Monday before the first day of the month
        const startOfCalendar = new Date(startOfMonth);
        startOfCalendar.setDate(startOfMonth.getDate() - ((startOfMonth.getDay() + 5) % 7));

        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const weeks = [];

        let day = new Date(startOfCalendar);

        while (day <= endOfMonth || day.getDay() !== 2) { // Continue until the last day of the month is a Sunday
            weeks.push(day.toISOString().slice(0, 10));
            day.setDate(day.getDate() + 1);
        }

        return {
            daysOfWeek,
            weeks
        }
    }, [currentDate]);

    if (!currentDate) {
        return null;
    }

    return (
        <div className="calendar">
            <div className="calendar-month-selection">
                <div className='grower'>&nbsp;</div>
                <button className='prev month' onClick={prevMonth}><img src={arrDown} alt={'Previous month'}/></button>
                <h3>
                    {currentDate.toLocaleString('default', {month: 'long'})} {currentDate.getFullYear()}
                </h3>
                <button className='next month' onClick={nextMonth}><img src={arrDown} alt={'Previous month'}/></button>
                <div className='grower'>&nbsp;</div>
            </div>
            <div className="calendar-grid">
                {daysOfWeek.map((day) => (
                    <div key={day} className="calendar-day">
                        {day}
                    </div>
                ))}
                {weeks.map((date, index) => {
                    const dayDate = new Date(date);
                    const isCurrentMonth = dayDate.getMonth() === currentDate.getMonth();
                    const today = new Date();
                    const isToday = dayDate.getFullYear() === today.getFullYear() && dayDate.getMonth() === today.getMonth() && dayDate.getDate() === today.getDate()
                    return (
                        <div
                            key={index}
                            className={`calendar-date ${isCurrentMonth ? 'current-month' : 'other-month'} ${isToday ? 'is-today' : ''}`}
                            onClick={_ => showCreateNewEvent(dayDate)}
                        >
                            <span className='date-number'>{dayDate.getDate()}</span>
                            {(eventsByDate[dayDate] ?? []).map(e => {
                                return <>
                                    <div className='calendar-event'
                                         key={e.id}
                                         onClick={_ => navigate(`/app/venue/${venue.id}/event/${e.id}/home?ref=cal`)}>
                                        <h5 className={e.sale_status}>{e.timestamp_from && e.timestamp_from} {e.title} {e.timestamp_to && ` - ${e.timestamp_to}`}</h5>
                                        <div className='hover-info'>
                                            <b>{e.title}</b>
                                            <br/>
                                            {e.timestamp_from && <p>{e.timestamp_from} - {e.timestamp_to && e.timestamp_to}</p>}
                                            <p><b>Forventet gæster:</b></p>
                                            <p>{e.expected_guests}</p>
                                            {e.sale_status === 'proposal' && <p><b>Tilbud</b></p>}
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
