import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import clear from '../../assets/clear-24px.svg'
import arrowDown from '../../assets/arr_down_white.svg'
import './simple-input.css'
import {useKeyboardShortcut} from "../../UseKeyboardShortcut";
import SimpleCheckbox from "../simple-checkbox/SimpleCheckbox";

export default function SimpleInput(
    {
        title,
        type,
        options,
        canSelectMultiple,
        placeholder,
        onChanged,
        value,
        onBlur,
        startWithFocus,
        onlySelectableOptions,
        error,
        disabled,
        displayClearIcon = false,
        submit,
        canCreateNew,
        onClickedCreateNew,
        required,
        className,
        readOnly,
        postfix='',
        ...props
    }) {
    const [focused, setFocused] = useState(false);
    const [searching, setSearching] = useState('')
    const inputRef = useRef()

    const proxyOnChanged = useCallback((newValue, clicked) => {
        if (type === 'checkbox') {
            onChanged(p => !p)
            return;
        }
        if (onlySelectableOptions) {
            if (clicked) {
                onChanged(newValue)
                setSearching('')
            } else {
                setSearching(newValue)
                onChanged('')
            }
        } else {
            onChanged(newValue)
        }
    }, [type, onChanged, onlySelectableOptions, searching]);

    let opts;
    if (options) {
        opts = options
            .filter((o, idx) => o === '' || options.indexOf(o) === idx)
            .filter(o => !onlySelectableOptions || o.toString().toLowerCase().startsWith(searching.toLowerCase()))
    }

    function enterClicked() {
        if (submit) submit()
        if (!focused || !opts) return;
        if (opts.length === 1) {
            proxyOnChanged(opts[0], true);
        }
    }

    useKeyboardShortcut({
        shortcutKeys: ['Enter'],
        callback: enterClicked,
        focused
    });

    useEffect(() => {
        if (startWithFocus && inputRef.current) {
            inputRef.current.focus()
        }
    }, [startWithFocus, inputRef]);

    const displayedValue = useMemo(() => {
        if (onlySelectableOptions && !value) return searching;
        return value;
    }, [type, onlySelectableOptions, searching, value]);

    if (type === 'checkbox') {
        return <SimpleCheckbox checked={value} onChange={onChanged} title={title} disabled={disabled}/>
    }

    return <div className={`simple-input ${disabled && 'disabled'} ${className ?? ''} ${(options && options.length > 0) ? 'with-options' : ''}`}
                onClick={e => e.stopPropagation()}>
        {(title || error) &&
            <label>{title}{required ? '*' : ''}{error && <label className='error'> - {error}</label>}</label>}
        <div className='simple-input-wrapper'>
            <input type={type}
                   {...props}
                   required={required}
                   readOnly={readOnly}
                   onFocus={_ => setFocused(true)}
                   onBlur={e => {
                       if (onBlur) onBlur()
                       if (!canSelectMultiple) setFocused(false)
                   }}
                   value={displayedValue + (focused ? '' : postfix)}
                   className={(onlySelectableOptions) ? 'non-selectable' : ''}
                   ref={inputRef}
                   placeholder={placeholder}
                   disabled={disabled}
                   onChange={e => {
                       e.stopPropagation();
                       e.preventDefault();
                       proxyOnChanged(e.target.value, false)
                   }}
            />
            {displayClearIcon && (value || searching) &&
                <img onClick={_ => proxyOnChanged('', true)} className='input-field-icon' src={clear} alt='clear'/>}
            {onlySelectableOptions &&
                <img onClick={_ => setFocused(p => !p)} className='input-field-icon' src={arrowDown} alt='open'/>}
        </div>
        {focused && ((opts && opts.length > 0) || canCreateNew) && (
            <ul className="simple-input-dropdown" onFocus={e => {
                e.stopPropagation();
                e.preventDefault();
                setFocused(true)
            }}>
                {opts && opts.map((o) => (canSelectMultiple ?
                        <li>
                            <SimpleCheckbox checked={value.includes(o)}
                                            onChange={v => {
                                                if (v) {
                                                    proxyOnChanged([...value, o], true)
                                                } else {
                                                    proxyOnChanged(value.filter(v => v !== o), true)
                                                }
                                            }} title={o}/>
                        </li> :
                        <li key={o} onMouseDown={() => proxyOnChanged(o, true)}>
                            {o}
                        </li>
                ))}
                {canCreateNew && <li className='create-new' onMouseDown={_ => {
                    onClickedCreateNew()
                }}>+ Opret ny</li>}
            </ul>
        )}
    </div>
}
