import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import WebFont from 'webfontloader';
import {Provider} from "react-redux";
import store from "./store";

WebFont.load({
    google: {
        families: [
            'Raleway:300,400,500,600,700',
            'Open Sans:300,400,500,600,700',
            'Roboto:300,400,500,600,700',
            'Arima Madurai', 'PT Sans', 'Lora',
            'Inter',
            'Leaguespartan',
        ],
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

