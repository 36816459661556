import React, {useCallback, useState} from 'react'
import InputField from "../input-field/InputField";
import SimpleButton from "../simple-button/SimpleButton";
import './new-user.css'


export default function NewUser({title, submit, showRole=false}) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')

    const onClicked = useCallback(() => {
        submit({
            name,
            email,
            phone,
            password,
            role,
        })
        setName('')
        setEmail('')
        setPhone('')
        setPassword('')
        setRole('')
    }, [submit, name, email, phone, password, role])

    return (<div className='new-user'>
        <h1>{title}</h1>
        <InputField title='name' value={name} onChanged={setName}/>
        <InputField title='email' value={email} onChanged={setEmail}/>
        <InputField title='phone' value={phone} onChanged={setPhone}/>
        <InputField title='password' value={password} onChanged={setPassword}/>
        {showRole && <InputField title='role' value={role} onChanged={setRole}
                                 onlySelectableOptions
                                 options={['developer', 'venue-owner', 'venue-client']}
        />}
        <SimpleButton disabled={
            !name || !email || !password || (showRole && !role)
        } value='Opret bruger' onClick={onClicked}/>
    </div>)
}
