import React, {useEffect, useState} from "react";
import PackageParentPage from "../../../component/package-components/package-parent/PackageParentPage";
import {useParams} from "react-router-dom";
import {proposalApi} from "../../../api";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import ProposalPage from "../proposal/proposal-page/ProposalPage";
import './event-proposal-page.css';
import back_with_text from "../../../assets/back_with_text.svg";
import document from "../../../assets/document.svg";
import edit from "../../../assets/pretty_edit.svg";
import locked from '../../../assets/lock_closed.svg';
import {toDanishDate} from "../../../component/date_functions";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";

export default function EventProposalPage() {
    const {venue_id, event_id} = useParams();

    const [proposals, setProposals] = useState(null);
    const [venueProposals, setVenueProposals] = useState(null);
    const [activeProposal, setActiveProposal] = useState(null);

    const [showAddNewProposal, setShowAddNewProposal] = useState(false);

    useEffect(() => {
        proposalApi.listProposalsForEvent(event_id).then(res => {
            setProposals(res || []);
        });
    }, []);

    useEffect(() => {
        if (!venue_id) return;
        proposalApi.listProposalsForVenue(venue_id).then(res => {
            setVenueProposals(res || []);
        });
    }, [venue_id]);

    if (!proposals) return <p>Loading...</p>

    return (<PackageParentPage>
        {!activeProposal && <div className='event-proposal-page'>
            <h2>General</h2>
            <div className='document-list'>
                {proposals.map(proposal => <div key={proposal.id} className='document'>
                    <img src={document}
                         className='document-icon'
                         alt='proposal template'/>
                    <h2>{proposal.name}</h2>
                    <label className='proposal-status'>
                        {proposal.locked === 1 && <img src={locked}/>}
                        {proposal.status?.toUpperCase()}
                    </label>
                    <hr/>
                    <div className='client-state'>
                        <label>Set af kunden:</label>
                        <label>{proposal.seen_by_customer_timestamp === null ? 'Nej' : toDanishDate(proposal.seen_by_customer_timestamp)}</label>
                    </div>
                    {proposal.locked === 1 && <div className='client-state'>
                        <label>Deadline:</label>
                        <label>{proposal.proposal_deadline === null ? 'Nej' : toDanishDate(proposal.proposal_deadline)}</label>
                    </div>}

                    <div className='grower'/>
                    {/*{proposal.locked === 1 && <SimpleButton*/}
                    {/*    secondary*/}
                    {/*    value='Kopiér som kladde'*/}
                    {/*/>}*/}
                    <SimpleButton
                        icon={edit} value='Gå til dokument'
                        onClick={() => setActiveProposal(proposal)}
                    />
                </div>)}
                <div className='document create-new'>
                    <img src={document}
                         className='document-icon'
                         alt='proposal template'/>
                    <h2></h2>
                    <hr/>
                    <div className='grower'/>
                    <SimpleButton
                        icon={edit} value='Tilføj nyt dokument'
                        onClick={() => setShowAddNewProposal(true)}
                    />
                </div>
            </div>
            {showAddNewProposal && <CenteredPopup className='add-new-document-popup' showPopup={showAddNewProposal} closePopup={setShowAddNewProposal}>
                <h2>Tilføjer nyt dokument</h2>
                <div className='document-list'>
                    {venueProposals.map(proposal => <div key={proposal.id} className='document'>
                        <img src={document}
                             className='document-icon'
                             alt='proposal template'/>
                        <h2>{proposal.name}</h2>
                        <label className='proposal-status'>
                            {proposal.locked === 1 && <img src={locked}/>}
                            {proposal.status?.toUpperCase()}
                        </label>
                        <hr/>

                        <div className='grower'/>
                        {/*{proposal.locked === 1 && <SimpleButton*/}
                        {/*    secondary*/}
                        {/*    value='Kopiér som kladde'*/}
                        {/*/>}*/}
                        <SimpleButton
                            icon={edit} value='Tilføj dokument til event'
                            onClick={() => {

                                proposalApi.addProposalToEvent(proposal.id, event_id).then(_ => {
                                });
                            }}
                        />
                    </div>)}
                </div>
            </CenteredPopup>}
        </div>}
        {activeProposal && <ProposalPage proposal_id={activeProposal.id} setProposals={setProposals} backClicked={_ => setActiveProposal(null)}/>}
    </PackageParentPage>)
}
