import React from 'react'
import './simple-button.css'

export default function SimpleButton({
                                         className,
                                         value,
                                         icon,
                                         onClick,
                                         hidden = false,
                                         disabled = false,
                                         loading = false,
                                         big = false,
                                         round,
                                         style,
                                         red = false,
                                         secondary = false,
    just_text=false,
                                         alwaysSelected = false,
                                         dangerous = false,
                                         isSubmit = false,
                                         ...rest
                                     }) {
    return (
        <button
            type={isSubmit ? 'submit' : 'button'}
            style={style}
            hidden={hidden}
            className={`simple-button ${className} ${red ? 'red' : ''} ${round ? 'round' : ''} ${secondary ? 'secondary' : ''} ${disabled ? 'disabled' : ''} ${loading ? 'loading' : ''} ${big ? 'big' : ''} ${
                dangerous ? 'dangerous' : ''} ${alwaysSelected ? 'always-selected' : ''} ${just_text ? 'just-text' : ''}`}
            disabled={disabled}
            onClick={() => {
                if (isSubmit || loading) return
                onClick()
            }}
            {...rest}
        >
            {icon ? <img src={icon} alt='' className='btn-icon'/> : ''}
            {value}
        </button>
    )
}
