import React from 'react'
import './side-popout.css'

export default function SidePopout({ className, children }) {
    return (
        <div className={`pop-out-container ${className}`}>
            {children}
        </div>
    )
}
