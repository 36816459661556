import React, {useCallback, useState} from 'react';
import './movable-window.css'
import lock_closed from '../../assets/lock_closed.svg';
import close from '../../assets/clear-24px.svg';

export default function MovableWindow({
                                          className,
                                          children,
                                          title,
                                          canClose,
                                          onClose,
                                          lockable = false,
                                          visible = true,
                                          startX = 450,
                                      }) {
    const [position, setPosition] = useState({x: startX, y: 50})

    const [dragging, setDragging] = useState(false);
    const [locked, setLocked] = useState(false);

    const mouseDown = useCallback(e => {
        if (locked) return;
        e.preventDefault()
        setDragging(true);
    }, [setDragging, locked]);

    const mouseUp = useCallback(e => {
        e.preventDefault()
        setDragging(false);

    }, [setDragging]);

    const mouseMove = useCallback(e => {
        e.preventDefault()
        e.stopPropagation()
        if (!dragging) return;
        setPosition(prev => ({
            x: prev.x + e.movementX,
            y: prev.y + e.movementY
        }))
    }, [dragging, setPosition]);

    return (<div
        style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
            cursor: dragging ? 'grabbing' : 'auto',
            zIndex: '5',
            display: visible ? 'block' : 'none'
        }}
        key={title}
        className={`fancy-border-outer ${className} ${locked ? 'locked' : ''}`}>
        <div className='fancy-border-inner'>
            <h2
                style={{
                    cursor: locked ? 'auto' : (dragging ? 'grabbing' : 'grab')
                }}
                onMouseDown={mouseDown}>
                {title}
            </h2>
            {lockable && <img className='lock' src={lock_closed} alt='lock closed' onClick={_ => setLocked(p => !p)}/>}
            {canClose && <img className='close' src={close} alt='close window' onClick={onClose}/>}
            {children}
        </div>
        {dragging && <div
            onMouseUp={mouseUp}
            onMouseMove={mouseMove} className='drag-catcher'>&nbsp;</div>}
    </div>)
}
