import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {proposalApi} from "../../../../api";
import SimpleButton from "../../../../component/simple-button/SimpleButton";
import {useDispatch} from "react-redux";
import {doGetUser} from "../../../../slice/userSlice";
import {PackageContext} from "../../../../component/package-components/package-parent/PackageParentPage";
import SimpleInput from "../../../../component/simple-input/SimpleInput";
import CenteredPopup from "../../../../component/centered-popup/CenteredPopup";
import './proposal-template-page.css';
import edit from '../../../../assets/pretty_edit.svg';
import document from '../../../../assets/document.svg';


export default function ProposalTemplatePage() {
    const {venue_id, event_id} = useParams()

    const {setDragging} = useContext(PackageContext);

    // proposals
    const [proposals, setProposals] = useState(null);
    const [newProposalName, setNewProposalName] = useState('');
    const [newProposalPopout, setNewProposalPopout] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(doGetUser())
    }, [dispatch]);

    useEffect(() => {
        if (event_id) {
            proposalApi.listProposalsForEvent(event_id).then(res => {
                setProposals(res || []);
            });
        } else {
            proposalApi.listProposalsForVenue(venue_id).then(res => {
                setProposals(res || []);
            });
        }
    }, [venue_id, event_id]);

    if (!proposals) {
        return <div>Loading...</div>
    }

    return (<div className='proposal-template-page' onDragEnd={_ => setDragging(null)}>
        <div className='header'>
            <div className='text-header'>
                <h1>Dokument Templates</h1>
                <p>
                    Her kan du oprette forskellige typer dokumenter som kan bruges til at sende tilbud og kontrakter til
                    kunder.
                    Opret eksempelvis en 'Standard tilbud' og en 'Kontrakt'.
                </p>
            </div>
            <div className='grower'/>
            {/*<SimpleButton secondary value='+ Gruppe' />*/}
            <SimpleButton value='+ Template' onClick={_ => setNewProposalPopout(true)} />
        </div>
        <div>
            <h2>General</h2>
            <div className='document-list'>
                {proposals.map(proposal => <div key={proposal.id} className='proposal'>
                    <img src={document}
                         className='document-icon'
                         alt='proposal template'/>
                    <h2>{proposal.name}</h2>
                    <hr/>
                    <p>
                        {proposal.description}
                    </p>
                    <SimpleButton icon={edit} value='Rediger' onClick={_ => {
                        navigate(`/app/venue/${venue_id}/dokument-template/${proposal.id}`)
                    }}/>
                </div>)}
            </div>
        </div>

        <CenteredPopup showPopup={newProposalPopout} closePopup={setNewProposalPopout}>
            <h2>Opret ny dokument template</h2>
            <SimpleInput
                title='Navn'
                type='text'
                value={newProposalName}
                onChanged={e => setNewProposalName(e)}
            />
            <br/>
            <div className='horizontal center'>
                <div className='grower'/>
                <SimpleButton
                    value='Opret'
                    onClick={_ => {
                        proposalApi.createProposal({
                            name: newProposalName,
                            event_id: event_id,
                            venue_id: venue_id
                        }).then(res => {
                            setNewProposalPopout(false);
                            setProposals(prev => [...prev, res]);
                        })
                    }}
                />
                <SimpleButton
                    secondary
                    value='Annuller'
                    onClick={_ => {
                        setNewProposalPopout(false);
                    }}
                />
            </div>
        </CenteredPopup>
    </div>)
}
