import React, {useCallback, useMemo, useState} from 'react'
import './tab-styles.css'
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectVenues} from "../../../slice/venueSlice";
import {selectVenueEvents} from "../../../slice/eventSlice";
import burgerMenu from '../../../assets/burger-menu-icon.png';
import Calendar from "../../../component/calendar/calendar";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import CreateNewEvent from "../../../component/create-new-event/CreateNewEvent";
import InputField from "../../../component/input-field-with-options/InputFieldWithOptions";
import {toDanishDate} from "../../../component/date_functions";
import checkmark from '../../../assets/checkmark.svg';

export default function EventsTab() {
    const [eventFilter, setEventFilter] = useState('future');
    const [eventSearch, setEventSearch] = useState('');
    const [creatingNewEvent, setCreatingNewEvent] = useState(false);
    const [dropdownSearchText, setDropdownSearchText] = useState('');
    const [defaultDate, setDefaultDate] = useState(null);

    let {venue_id: _venue_id, view: _view} = useParams();
    const venue_id = useMemo(() => parseInt(_venue_id), [_venue_id])

    const view = useMemo(() => {
        if (!_view) return 'list';
        return _view;
    }, [_view]);

    const navigate = useNavigate();

    const venues = useSelector(selectVenues);
    const events = useSelector(selectVenueEvents(venue_id));

    const venue = useMemo(() => {
        if (!venues || !venue_id) return null;
        return venues.find(v => v.id === venue_id)
    }, [venue_id, venues]);

    const _setSelectedLayout = useCallback(newView => {
        console.log('newView', newView)
        navigate(`/app/venue/${venue.id}/events/${newView}`, {
            replace: true,
        })
    }, [venue, navigate]);

    const openCreatingNewEvent = useCallback(date => {
        setCreatingNewEvent(true);
        setDefaultDate(toDanishDate(date));
    }, []);

    return (<section className='event-tab'>
        <div className='layout-selection-wrapper'>
            <div className='layout-selection' onClick={_ => _setSelectedLayout('list')}>
                <img src={burgerMenu} alt='liste' className='small-icon'/>
                Listevisning
            </div>
            <div className='layout-selection' onClick={_ => _setSelectedLayout('calendar')}>
                <img src={burgerMenu} alt='liste' className='small-icon'/>
                Kalendervisning
            </div>
            <div className='grower'>&nbsp;</div>

            {/*<SimpleButton value='Opret tilbud' onClick={_ => setCreatingNewEvent(true)}/>*/}
            <SimpleButton value='Opret event' onClick={_ => setCreatingNewEvent(true)}/>

            <CreateNewEvent default_date={defaultDate}
                            callback={_ => setCreatingNewEvent(false)}
                            showPopup={creatingNewEvent}
                            closePopup={setCreatingNewEvent}
            />
        </div>
        {view === 'list' && <div className='event-list-wrapper'>
            <div className='event-filter-wrapper'>
                <input type='text' id='search' placeholder='Søg efter event' className='search-input'
                       value={eventSearch} onChange={e => {
                    setEventSearch(e.target.value)
                    setEventFilter('all')
                }}/>

                <div className='grower'>&nbsp;</div>
                <SimpleButton className='event-filter-btn' value={eventFilter !== 'future' ? 'Afviklede' : 'Se afviklede'} secondary={eventFilter !== 'future'} disabled={eventFilter !== 'future'}
                              onClick={_ => setEventFilter('all')} />
                <SimpleButton className='event-filter-btn' value={eventFilter === 'future' ? 'Kommende' : 'Se kommende'} secondary={eventFilter === 'future'} disabled={eventFilter === 'future'}
                              onClick={_ => setEventFilter('future')} />
            </div>
            <table className='event-table'>
                <thead>
                <tr>
                    <th>Eventnavn</th>
                    <th>Dato</th>
                    <th>Tidsrum</th>
                    <th>Lokale</th>
                </tr>
                </thead>
                <tbody>
                {events
                    .filter(e => eventSearch !== '' || eventFilter === 'all' || new Date(e.date).setHours(23) > new Date())
                    .filter(e => e.title.toLowerCase().includes(eventSearch.toLowerCase()))
                    .filter(e => e.sale_status !== 'lost')
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map(e =>
                        <tr
                            key={e.id}
                            onClick={_ => navigate(`/app/venue/${venue.id}/event/${e.id}/home`)}
                            className='event'>
                            <td className='sale-status-td'>
                                <a href={`/app/venue/${venue.id}/event/${e.id}/home`}>{e.title}</a>
                                {e.sale_status === 'proposal' && <div className={`sale-status ${e.sale_status}`}>{{
                                    'proposal': 'Tilbud',
                                    'won': 'Vundet',
                                    'lost': 'Tabt'
                                }[e.sale_status]}</div>}
                            </td>
                            <td>{new Date(e.date).toDateString()}</td>
                            <td>{e.timestamp_from && e.timestamp_from}-{e.timestamp_to && e.timestamp_to}</td>
                            <td>{e.room_names && e.room_names.join(' & ')}</td>
                            <td className='hover-info'>
                                <b>{e.title}</b>
                                <br/>
                                <p><b>Forventet gæster:</b></p>
                                <p>{e.expected_guests}</p>
                                {e.sale_status === 'proposal' && <p><b>Status:</b> {e.sale_status}</p>}
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </div>}
        {view === 'calendar' && <div>
            <Calendar showCreateNewEvent={openCreatingNewEvent} venue={venue} events={events}/>
        </div>}
    </section>)
}
