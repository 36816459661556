import React from 'react'
import './simple-card.css'

export default function SimpleCard({ title, text }) {
    return (
        <div className="simple-card">
            <div className="simple-card-title">{title}</div>
            <div className="simple-card-text">{text}</div>
        </div>
    )
}
