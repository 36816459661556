import React from "react"
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import LoginPage from "./page/login/LoginPage";
import HomePage from "./page/home/HomePage";
import RequireAuth from "./auth/RequireAuth";
import SeatingPage from "./page/seating/SeatingPage";
import AdminPage from "./page/admin/AdminPage";
import VenuePage from "./page/venue-page-wrapper/venue/VenuePage";
import RoomPage from "./page/room/RoomPage";
import Logout from "./component/Logout";
import SignupPage from "./page/signup-page/SignupPage";
import WaiterList from "./page/event/waiter-list/WaiterList";
import VenuePageWrapper from "./page/venue-page-wrapper/VenuePageWrapper";
import DoorList from "./page/event/door-list/DoorList";
import VenueClientMenuWrapper from "./page/venue-client/venue-client-menu-wrapper/VenueClientMenuWrapper";
import GuestManagementPage from "./page/venue-client/guest-management-page/GuestManagementPage";
import VenueClientSeatingPage from "./page/venue-client/venue-client-seating-page/VenueClientSeatingPage";
import EventPageWrapper from "./page/event/event-page-wrapper/EventPageWrapper";
import LandingPage from "./page/landing/LandingPage";
import EventsTab from "./page/venue-page-wrapper/tabs/events-tab";
import LokalerTab from "./page/venue-page-wrapper/tabs/lokaler-tab";
import ElementTab from "./page/venue-page-wrapper/tabs/element-tab";
import CalendarSetupTab from "./page/venue-page-wrapper/tabs/calendar-setup-tab";
import ExportEventPage from "./page/export-event-page/ExportEventPage";
import ClientProposalPage from "./page/client/proposal/ClientProposalPage";
import ProposalTemplatePage from "./page/event/proposal/proposal-template-page/ProposalTemplatePage";
import EconomicOverviewPage from "./page/economic-overview/EconomicOverviewPage";
import ProposalPage from "./page/event/proposal/proposal-page/ProposalPage";
import HostVenuePage from "./page/host/HostVenuePage";
import HostClientPage from "./page/host/host-client-page/HostClientPage";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/login' element={<LoginPage/>}/>
                <Route exact path='' element={<LandingPage/>}/>
                <Route exact path='/logout' element={<Logout/>}/>

                <Route exact path='/admin' element={<RequireAuth>
                    <AdminPage/>
                </RequireAuth>}/>
                <Route exact path='/admin/:section' element={<RequireAuth>
                    <AdminPage/>
                </RequireAuth>}/>

                <Route exact path='host' element={<RequireAuth>
                    <HostClientPage/>
                </RequireAuth>}/>

                <Route path="/app/venue" element={<RequireAuth><VenuePageWrapper/></RequireAuth>}>
                    <Route path='/app/venue/:venue_id/' element={<VenuePage/>}>
                        <Route path='/app/venue/:venue_id/' element={<EventsTab/>}/>
                        <Route path='/app/venue/:venue_id/events' element={<EventsTab/>}/>
                        <Route path='/app/venue/:venue_id/events/:view' element={<EventsTab/>}/>
                        <Route path='/app/venue/:venue_id/borde-elementer' element={<ElementTab/>}/>
                        <Route path='/app/venue/:venue_id/lokaler' element={<LokalerTab/>}/>
                        <Route path='/app/venue/:venue_id/kalender-abonnement' element={<CalendarSetupTab/>}/>
                        <Route path='/app/venue/:venue_id/dokument-template' element={<ProposalTemplatePage/>}/>
                        <Route path='/app/venue/:venue_id/dokument-template/:proposal_id' element={<ProposalPage/>}/>
                        <Route path='/app/venue/:venue_id/economy' element={<EconomicOverviewPage/>}/>
                        <Route path='/app/venue/:venue_id/hosts' element={<HostVenuePage/>}/>
                    </Route>

                    <Route exact path='/app/venue/:venue_id/event/:event_id/:tab' element={<EventPageWrapper/>} />
                    <Route exact path='/app/venue/:venue_id/event/:event_id/:tab/:subtab' element={<EventPageWrapper/>} />
                </Route>

                <Route path='/app' element={<RequireAuth><VenueClientMenuWrapper/></RequireAuth>}>
                    <Route path='/app' element={<HomePage/>}/>
                    <Route path='/app/guest' element={<GuestManagementPage/>}/>
                    <Route path='/app/seating' element={<VenueClientSeatingPage/>}/>
                    <Route path='/app/export' element={<DoorList/>}/>
                </Route>
                <Route path='/proposal' element={<RequireAuth><ClientProposalPage/></RequireAuth>}/>

                <Route exact path='/venue/:venue_id/event/:event_id/waiter-export/print'
                       element={<RequireAuth><WaiterList/></RequireAuth>}
                />

                <Route exact path='/app/event/:event_id/room/:room_id/seating' element={<RequireAuth>
                    <SeatingPage/>
                </RequireAuth>}/>
                <Route exact path='/app/venue/:venue_id/event/:event_id/seating' element={<RequireAuth>
                    <SeatingPage/>
                </RequireAuth>}/>

                <Route exact path='/app/venue/:venue_id/event/:event_id/room/:room_id/seating' element={<RequireAuth>
                    <SeatingPage/>
                </RequireAuth>}/>

                <Route exact path='/app/room/:room_id' element={<RequireAuth>
                    <RoomPage/>
                </RequireAuth>}/>
                <Route exact path='/app/room/:room_id/export' element={<RequireAuth>
                    <ExportEventPage/>
                </RequireAuth>}/>

                <Route exact path='/invitation/:token' element={
                    <SignupPage/>
                }/>

                <Route exact path='/proposal/:proposalToken' element={<ClientProposalPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}
