import React, {useEffect, useMemo, useState} from "react";
import {EventApi, GuestAPI, hostApi, VenueAPI} from "../../../api";
import './host-client-page.css';
import SimpleInput from "../../../component/simple-input/SimpleInput";
import SimpleCheckbox from "../../../component/simple-checkbox/SimpleCheckbox";
import CenteredPopup from "../../../component/centered-popup/CenteredPopup";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import {prettyTimeDifference, toDanishDate, toDanishTime} from "../../../component/date_functions";
import backwithtext from '../../../assets/back_with_text.svg';
import ValueKeyLabel from "../../../component/value-key-label/ValueKeyLabel";
import checkmark from '../../../assets/checkmark_green.svg';

export default function HostClientPage() {
    const [venues, setVenues] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState(null);

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [eventGuests, setEventGuests] = useState(null);

    const [hostName, setHostName] = useState('');
    const [doorName, setDoorName] = useState('');
    const [showPopup, setShowPopup] = useState(true);

    const [selectedGuest, setSelectedGuest] = useState(null);

    const [checkinComment, setCheckinComment] = useState('');

    const [checkins, setCheckins] = useState([]);

    const [searchField, setSearchField] = useState('');

    useEffect(() => {
        VenueAPI.list_all_venues().then(vens => {
            setVenues(vens);
            if (vens.length === 1) {
                setSelectedVenue(vens[0]);
            }
        });
    }, []);

    useEffect(() => {
        if (!selectedVenue) return;
        setEvents([]);
        VenueAPI.list_venue_events(selectedVenue.id).then(events => {
            setEvents(events);
        });
    }, [selectedVenue]);

    useEffect(() => {
        if (!selectedEvent) return;
        GuestAPI.listEventGuests(selectedEvent.id).then(guests => {
            setEventGuests(guests);
        });
        hostApi.listEventCheckins(selectedEvent.id).then(setCheckins);
    }, [selectedEvent]);

    useEffect(() => {
        if (!selectedEvent) return;

        // fetch checkins every 10 seconds
        const interval = setInterval(() => {
            hostApi.listEventCheckins(selectedEvent.id).then(setCheckins);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const guestsWithCheckins = useMemo(() => {
        if (!eventGuests) return [];
        return eventGuests.map(g => {
            return {
                ...g,
                checkins: checkins.filter(c => c.guest_id === g.id)
            }
        })
    }, [eventGuests, checkins]);

    const {hasCheckedIn, hasNotCheckedIn} = useMemo(() => {
        if (!guestsWithCheckins) return {hasCheckedIn: 0, hasNotCheckedIn: 0};
        const hasCheckedIn = guestsWithCheckins.filter(g => g.checkins.length > 0);
        return {hasCheckedIn: hasCheckedIn.length, hasNotCheckedIn: guestsWithCheckins.length - hasCheckedIn.length};
    }, [guestsWithCheckins]);

    console.log(guestsWithCheckins)

    return (<div className='host-client-page'>
        <main>
            <div className='checkin-header'>
                <div className='horizontal center'>
                    {!selectedEvent && <h3>Vært tjek ind side</h3>}
                    {selectedEvent && <h3>{selectedEvent.title}</h3>}

                    <div className='grower'/>

                    <SimpleInput
                        placeholder='Værtnavn'
                        value={hostName}
                        onChanged={setHostName}
                    />
                    &nbsp;
                    <SimpleInput
                        placeholder='Dørnavn'
                        value={doorName}
                        onChanged={setDoorName}
                    />
                    <div className='grower'/>
                    <SimpleButton
                        value='log ud'
                        onClick={_ => {
                            window.location.href = '/logout'
                        }}
                    />
                </div>
                {!selectedVenue && venues && venues.map(venue => <div
                    key={venue.id}
                    className={`venue ${selectedVenue && selectedVenue.id === venue.id ? 'selected' : ''}`}
                    onClick={() => setSelectedVenue(venue)}>
                    {venue.name}
                </div>)}
                {!selectedEvent && <table className='event-table'>
                    <thead>
                    <tr>
                        <th>Eventnavn</th>
                        <th>Dato</th>
                        <th>Tidsrum</th>
                        <th>Lokale</th>
                        <th>Gæster</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events
                        .filter(e => new Date(e.date).setHours(23) > new Date())
                        .filter(e => e.sale_status !== 'lost')
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map(e =>
                            <tr
                                key={e.id}
                                onClick={_ => {
                                    const timediff = Math.abs(new Date(e.date) - new Date())
                                    if (timediff < 0) {
                                        if (window.confirm('Eventet er allerede startet. Vil du fortsætte?')) {
                                            setSelectedEvent(e);
                                        }
                                    } else if (timediff > 1000 * 60 * 60 * 24) {
                                        if (window.confirm('Eventet er mere end 24 timer væk. Vil du fortsætte?')) {
                                            setSelectedEvent(e);
                                        }
                                    } else {
                                        setSelectedEvent(e);
                                    }
                                }}
                                className='event'>
                                <td className='sale-status-td'>
                                    {e.title}
                                    {e.sale_status === 'proposal' && <div className={`sale-status ${e.sale_status}`}>{{
                                        'proposal': 'Tilbud',
                                        'won': 'Vundet',
                                        'lost': 'Tabt'
                                    }[e.sale_status]}</div>}
                                </td>
                                <td>{new Date(e.date).toDateString()}</td>
                                <td>{e.timestamp_from && e.timestamp_from}-{e.timestamp_to && e.timestamp_to}</td>
                                <td>{e.room_names && e.room_names.join(' & ')}</td>
                                <td>{e.expected_guests}</td>
                            </tr>)}
                    </tbody>
                </table>}
                {selectedEvent && <div>
                    <img src={backwithtext} alt='back' onClick={_ => setSelectedEvent(null)}/>
                    <div className='horizontal center'>
                    </div>
                    {(hostName && doorName) && <SimpleInput
                        className='guest-search-bar'
                        placeholder='Search'
                        value={searchField}
                        onChanged={setSearchField}
                        displayClearIcon
                    />}
                </div>}
                {(!hostName || !doorName) && <div className='horizontal center'>
                    <p><b>Indtast venligst værtnavn og dørnavn for at komme videre</b></p>
                </div>}
            </div>
            {selectedEvent && hostName && doorName && <div className='table-scrollable'>
                <table className='guest-table'>
                    <thead>
                    <tr>
                        <th>Antal</th>
                        <th>Navn</th>
                        <th>Firma</th>
                        <th>Ankommet</th>
                    </tr>
                    </thead>
                    <tbody>
                    {guestsWithCheckins && guestsWithCheckins
                        .filter(g => g.name.toLowerCase().includes(searchField.toLowerCase()) || g.company.toLowerCase().includes(searchField.toLowerCase()))
                        .map(g => <tr key={g.id}
                                      onClick={_ => setSelectedGuest(g)}>
                            <td>{g.count}</td>
                            <td>{g.name} {g.checkins?.length > 0 && <img src={checkmark}/>}</td>
                            <td>{g.company}</td>
                            <td>{g.checkins?.map(c => {
                                return <div key={c.id}>
                                    {toDanishTime(c.timestamp_created)} ({prettyTimeDifference(new Date(), new Date(c.timestamp_created))})
                                    {c.comment && <label> <b>({c.comment})</b></label>}
                                </div>
                            })}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </main>
        <div className='footer'>
            <label>Check ind status: {hasCheckedIn}</label>
            <b>/</b>
            <label className='overall-checkin-count'>
                {guestsWithCheckins?.length}
            </label>
        </div>
        {selectedGuest && <CenteredPopup className='checkin-popup' showPopup={selectedGuest !== null}
                                         closePopup={_ => setSelectedGuest(null)}>
            <h2>Tjek ind <b>{selectedGuest.name}</b></h2>
            <div className='count-wrapper'>
                <label className={'count-label'}>{selectedGuest.count} </label>
                <label>personer i selskabet</label>
            </div>
            <div>
                <ValueKeyLabel title='Firmanavn'>
                </ValueKeyLabel>
                {selectedGuest.company}

                <br />
                <br />

                {selectedGuest.comment && <ValueKeyLabel title='Seating kommentar'>
                </ValueKeyLabel>}
                {selectedGuest.comment}
            </div>
            <div className='prev-checkins'>
                <b>Tidligere indtjekninger</b>
                <hr/>
                {selectedGuest.checkins.length === 0 && <p>Ingen tidligere indtjekninger</p>}
                {selectedGuest.checkins.length > 0 && <table>
                    <thead>
                    <tr>
                        <th>Dør</th>
                        <th>Vært</th>
                        <th>Tidspunkt</th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedGuest.checkins.map(c => <tr key={c.id}>
                        <td>{c.door_name}</td>
                        <td>{c.host_name}</td>
                        <td>{toDanishTime(c.timestamp_created)} ({prettyTimeDifference(new Date(), new Date(c.timestamp_created))})</td>
                    </tr>)}
                    </tbody>
                </table>}
            </div>

            <SimpleInput
                value={checkinComment}
                onChanged={setCheckinComment}
                title='Kommentar'
            />
            <div className='horizontal'>
                <div className='grower'/>
                <SimpleButton
                    value='Check in'
                    big
                    onClick={_ => {
                        hostApi.checkinGuest(
                            selectedEvent.id,
                            selectedGuest.id,
                            hostName,
                            doorName,
                            checkinComment,
                            true).then(allCheckins => {
                                setSelectedGuest(null);
                                setCheckins(allCheckins);
                                setCheckinComment('');
                            }
                        )
                    }}
                />
            </div>
        </CenteredPopup>}

        <CenteredPopup showPopup={showPopup} closePopup={null} className='checkin-popup'>
            <h2>Indtast navn og lokation for at komme igang</h2>

            <SimpleInput
                title='Værtnavn'
                value={hostName}
                onChanged={setHostName}
            />
            <br/>
            <SimpleInput
                title='Dør'
                value={doorName}
                onChanged={setDoorName}
            />
            <br/>
            <SimpleButton
                value='Start'
                disabled={!hostName || !doorName}
                onClick={_ => setShowPopup(false)}
            />
        </CenteredPopup>
    </div>)
}
