import React, {useCallback, useEffect, useState} from 'react'
import './login.css'
import InputField from "../../component/input-field/InputField";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useDispatch, useSelector} from "react-redux";
import {
    loginUser,
    selectLoginLoading, selectUser,
    selectUserError,
    selectUserLoading,
    selectUserLoggedIn
} from "../../slice/userSlice";
import {useNavigate} from 'react-router-dom';
import LoadingAnimation from "../../component/loading-animation/LoadingAnimation";
import logoName from '../../assets/openseat_logo_name.svg'

export default function LoginPage() {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const navigate = useNavigate()

    const isLoggedIn = useSelector(selectUserLoggedIn)
    const loading = useSelector(selectUserLoading)
    const loginError = useSelector(selectUserError)
    const loginLoading = useSelector(selectLoginLoading)
    const user = useSelector(selectUser)

    useEffect(() => {
        if (!isLoggedIn || !user || Object.keys(user).length === 0) return;

        const redir_path = localStorage.getItem('redirAfterLogin')
        if (redir_path && redir_path !== '/login') {
            localStorage.removeItem('redirAfterLogin')
            navigate(redir_path)
        } else {
            if (['developer', 'venue-owner', 'venue-client'].indexOf(user.role) !== -1) {
                navigate(`/app`)
            } else {
                navigate('/host')
            }
        }
    }, [navigate, isLoggedIn, user]);

    const submit = useCallback(() => {
        dispatch(loginUser({email, password, remember_me: rememberMe}))
    }, [dispatch, email, password, rememberMe]);

    return (<div className='login-page'>
        <div className='left-menu'>
            <img src={logoName} alt={'Openseat logo'}/>
        </div>
        <div className='right-menu'>
            <div className='wrapper'>
                <InputField
                    placeholder='Brugernavn (eller email)'
                    value={email}
                    onChanged={setEmail}
                    displayClearIcon
                    submit={submit}
                    error={loginError && loginError.message}
                />
                <InputField
                    placeholder='Indtast password'
                    value={password}
                    onChanged={setPassword}
                    type='password'
                    displayClearIcon
                    submit={submit}
                />
                <label className='remember-me'>
                    <input type={'checkbox'} id={'remember-me'} checked={rememberMe} onChange={t => setRememberMe(t.target.checked)}/>
                    Husk mig
                </label>
                {loginLoading && <LoadingAnimation/>}
                <SimpleButton big disabled={loginLoading} value='LOGIN' onClick={submit} loading={loading}/>
            </div>
        </div>
    </div>)
}
