// NewCustomerPopup.js
import React, {useCallback, useState} from 'react'
import SimpleInput from "../../simple-input/SimpleInput";
import SimpleButton from "../../simple-button/SimpleButton";
import './new-customer.css';
import {customerApi} from "../../../api";

export default function NewCustomerPopup({title, venue_id, isOpen, onClose, createdNewCustomer}) {
    const [companyName, setCompanyName] = useState('')
    const [companyCvr, setCompanyCvr] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactPhone, setContactPhone] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [role, setRole] = useState('venue-client')
    const [loginUsername, setLoginUsername] = useState('')
    const [password, setPassword] = useState('')

    const onClicked = useCallback(() => {
        customerApi.createCustomer({
            venue_id: venue_id,
            company_name: companyName,
            company_cvr: companyCvr,
            company_address: companyAddress,
            contact_name: contactName,
            contact_phone: contactPhone,
            contact_email: contactEmail,
            role: role,
            login_username: loginUsername,
            password: password
        }).then(cust => {
            createdNewCustomer && createdNewCustomer(cust)
            setCompanyName('')
            setCompanyCvr('')
            setCompanyAddress('')
            setContactName('')
            setContactPhone('')
            setContactEmail('')
            setRole('venue-client')
            setLoginUsername('')
            setPassword('')
            onClose && onClose()
        })
    }, [
        venue_id,
        companyName,
        companyCvr,
        companyAddress,
        contactName,
        contactPhone,
        contactEmail,
        role,
        loginUsername,
        password,
        onClose,
        createdNewCustomer
    ])

    if (!isOpen) return null

    return (
        <div className='new-customer-popup-overlay'>
            <div className='new-customer-popup'>
                <h1>{title}</h1>
                <div className='columns'>
                    <div className='column'>
                        <SimpleInput title='Virksomhedsnavn' value={companyName} onChanged={setCompanyName}/>
                        <SimpleInput title='CVR-nr' value={companyCvr} onChanged={setCompanyCvr}/>
                        <div>
                            <h4>Kontaktperson</h4>
                            <SimpleInput placeholder='Navn' required value={contactName} onChanged={setContactName}/>
                            <SimpleInput placeholder='Telefon' value={contactPhone} onChanged={setContactPhone}/>
                            <SimpleInput placeholder='E-mail' value={contactEmail} onChanged={setContactEmail}/>
                        </div>
                        <SimpleInput title='Adresse' value={companyAddress} onChanged={setCompanyAddress}/>
                    </div>
                    <div className='column'>
                        <SimpleInput title='Login Username' required value={loginUsername}
                                     onChanged={setLoginUsername}/>
                        <SimpleInput title='Password' required value={password} onChanged={setPassword}/>
                    </div>
                </div>
                <p><b>*</b> er nødvendige felter</p>

                <div className='buttons'>
                    <SimpleButton secondary value='Luk' onClick={onClose}/>
                    <SimpleButton
                        disabled={!contactName || !loginUsername || !password}
                        value='Opret kunde'
                        onClick={onClicked}
                    />
                </div>
            </div>
        </div>
    )
}
