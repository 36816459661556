import React, {useCallback, useState} from "react";
import SidePopout from "../../side-popout/SidePopout";
import cross from "../../../assets/clear-24px.svg";
import SimpleInput from "../../simple-input/SimpleInput";
import SimpleTextarea from "../../simple-input/simple-textarea";
import SimpleButton from "../../simple-button/SimpleButton";
import {packageApi} from "../../../api";
import './new-package-popout.css';

export default function NewPackagePopout({
                                             venue_id,
                                             event_id,
                                             proposal_id,
                                             createdNewPackage,
                                             onClose,
                                             title = 'Opret ny pakke',
                                             button_text = 'Opret ny pakke',
                                             type = 'multiple',
                                             price_type = 'flat'
                                         }) {
    const [newPackageTitle, setNewPackageTitle] = useState('');
    const [newPackageDescription, setNewPackageDescription] = useState('');
    const [newPackageNettoPrice, setNewPackageNettoPrice] = useState(0);
    const [newPackageTotalPrice, setNewPackageTotalPrice] = useState(0);
    const [priceType, setPriceType] = useState('flat');

    const createNewPackage = useCallback(() => {
        if (!newPackageTitle || (!venue_id && !event_id)) {
            window.alert('Du skal udfylde titel og vælge en event eller venue');
            return;
        }
        packageApi.createPackage(newPackageTitle, null, null, proposal_id, type, priceType, newPackageDescription)
            .then(p => {
                if (p && p.id) {
                    createdNewPackage(p);
                    setNewPackageTitle('');
                    setNewPackageDescription('');
                    setPriceType('flat');

                    if (type === 'economy') {
                        packageApi.createOptionForPackage(p.id, '', '', newPackageNettoPrice, newPackageTotalPrice).then(() => {
                            setNewPackageNettoPrice(0)
                            setNewPackageTotalPrice(0)
                            onClose();
                        })
                    } else {
                        onClose();
                    }
                }
            });
    }, [newPackageTitle, newPackageDescription, venue_id, event_id, proposal_id, type, onClose, newPackageNettoPrice, newPackageTotalPrice, priceType]);

    return (<SidePopout className='new-package-popout'>
        <div className='horizontal'>
            <h3>{title}</h3>
            <img className='close-popout'
                 src={cross}
                 alt={'close'} onClick={onClose}/>
        </div>
        <SimpleInput
            title='Titel'
            type="text"
            value={newPackageTitle}
            onChanged={e => setNewPackageTitle(e)}
        />
        <SimpleTextarea
            title='Beskrivelse'
            type="text"
            value={newPackageDescription}
            onChanged={e => setNewPackageDescription(e)}
        />
        <SimpleInput
            options={['Pax', 'Flad rate']}
            title='Prisstruktur'
            onlySelectableOptions
            onChanged={e => setPriceType({
                'Pax': 'px', 'Flad rate': 'flat',
            }[e])}
            value={{
                'px': 'Pax', 'flat': 'Flad rate'
            }[priceType]}
        />

        {type === 'economy' && <>
            <SimpleInput
                title='Netto'
                type="number"
                value={newPackageNettoPrice}
                onChanged={e => setNewPackageNettoPrice(e)}
            />
            <SimpleInput
                title='Total'
                type="number"
                value={newPackageTotalPrice}
                onChanged={e => setNewPackageTotalPrice(e)}
            />
        </>}
        <SimpleButton onClick={createNewPackage} value={button_text}/>
    </SidePopout>)
}
