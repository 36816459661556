import React, {useCallback, useContext, useMemo, useState} from "react";
import SimpleCheckbox from "../../../simple-checkbox/SimpleCheckbox";
import {packageApi} from "../../../../api";
import {PackageContext} from "../../package-parent/PackageParentPage";
import debounce from "lodash.debounce";
import SimpleTextarea from "../../../simple-input/simple-textarea";
import './proposal-option.css';
import SimpleInput from "../../../simple-input/SimpleInput";
import trash from '../../../../assets/bin_v2.svg';

export default function ProposalOption({
                                           pkg, opt, className = '', locked = false
                                       }) {

    const {setEditingOption, setPackages} = useContext(PackageContext);

    const [editingPrice, setEditingPrice] = useState(false);
    const [localPrice, setLocalPrice] = useState('');

    // name
    const [editingName, setEditingName] = useState(false);
    const [localName, setLocalName] = useState('');
    const updateNameBackend = useCallback((updated_name) => {
        packageApi
            .updateOption(pkg.id, opt.id, {
                name: updated_name
            })
            .then(o => {
                setPackages(prev => prev.map(p => {
                    if (p.id === pkg.id) {
                        return {...p, options: p.options.map(opt => opt.id === o.id ? o : opt)}
                    }
                    return p
                }))
            })
    }, [pkg, opt]);

    const _updateName = useMemo(() => debounce(updateNameBackend, 800), [updateNameBackend]);

    const updateName = useCallback((name) => {
        _updateName(name)
        setLocalName(name)
    }, [_updateName]);


    // Description
    const [editingDescription, setEditingDescription] = useState(false);
    const [localDescription, setLocalDescription] = useState('');
    const updateDescriptionBackend = useCallback((updated_msg) => {
        packageApi
            .updateOption(pkg.id, opt.id, {
                description: updated_msg
            })
            .then(o => {
                setPackages(prev => prev.map(p => {
                    if (p.id === pkg.id) {
                        return {...p, options: p.options.map(opt => opt.id === o.id ? o : opt)}
                    }
                    return p
                }))
            })
    }, [pkg, opt]);

    const _updateDescription = useMemo(() => debounce(updateDescriptionBackend, 800), [updateDescriptionBackend]);

    const updateDescription = useCallback((msg) => {
        _updateDescription(msg)
        setLocalDescription(msg)
    }, [_updateDescription]);

    return (<div className='proposal-option' key={opt.id}>
        <div className='horizontal center option-header'>
            <div className='horizontal'>
                <SimpleCheckbox
                    checked={opt.selected}
                    disabled={locked}
                    onChange={val => {
                        packageApi.selectOption(pkg.id, opt.id, val).then(() => {
                            setPackages(prev => prev.map(p => {
                                if (p.id === pkg.id) {
                                    return {
                                        ...p, options: p.options.map(o => {
                                            if (o.id === opt.id) {
                                                return {...o, selected: val}
                                            }
                                            return o
                                        })
                                    }
                                }
                                return p
                            }))
                        })
                    }}
                />
                {!editingName && <p className={locked ? '' : 'editable'} onClick={_ => {
                    if (locked) return;
                    setEditingName(true);
                    setLocalName(opt.name)
                }}>{opt.name}{opt.name.length === 0 && <label className='empty'>Navn</label>}</p>}
                {editingName && <SimpleInput
                    startWithFocus
                    value={localName}
                    onChanged={updateName}
                    onBlur={_ => setEditingName(false)}
                />}
            </div>

            {!locked && <img src={trash}
                             alt={'delete'}
                             className='delete hide-in-print'
                             onClick={e => {
                                 e.stopPropagation();
                                 packageApi.deleteOption(opt.package_id, opt.id).then(_ => {
                                     setPackages(prev => prev.map(p => {
                                         if (p.id === opt.package_id) {
                                             return {...p, options: p.options.filter(o => o.id !== opt.id)}
                                         }
                                         return p
                                     }))
                                 })
                             }}/>}
        </div>

        <hr/>

        {!editingDescription && <p className={locked ? '' : 'editable'} onClick={_ => {
            if (locked) return;
            setEditingDescription(true)
            setLocalDescription(opt.description)
        }}>{opt.description}{!opt.description && <label className='empty'>Beskrivelse</label>}</p>}
        {editingDescription && <SimpleTextarea
            startWithFocus
            value={localDescription}
            onChanged={updateDescription}
            onBlur={_ => setEditingDescription(false)}
        />}
        <hr/>
        <div className='option-controls'>
            <SimpleInput
                title='Udbudspris'
                value={opt.total_amount}
                postfix={' kr'}
                onChanged={val => {
                    setPackages(prev => prev.map(p => {
                        if (p.id === pkg.id) {
                            return {
                                ...p, options: p.options.map(o => {
                                    if (o.id === opt.id) {
                                        return {...o, total_amount: val}
                                    }
                                    return o
                                })
                            }
                        }
                        return p
                    }))
                    packageApi.updateOption(pkg.id, opt.id, {total_amount: val}).then(upt => {
                        setPackages(prev => prev.map(p => {
                            if (p.id === pkg.id) {
                                return {...p, options: p.options.map(o => o.id === opt.id ? upt : o)}
                            }
                            return p
                        }))
                    })
                }}
            />
        </div>
    </div>)
}
