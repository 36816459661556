import React, {useCallback, useContext, useMemo, useState} from "react";
import {PackageContext} from "../package-parent/PackageParentPage";
import {packageApi} from "../../../api";
import './proposal-package.css';
import SimpleTextarea from "../../simple-input/simple-textarea";
import debounce from "lodash.debounce";
import SimpleInput from "../../simple-input/SimpleInput";
import ProposalOption from "./proposal-option/ProposalOption";
import bin from "../../../assets/bin_v2.svg";
import SimpleButton from "../../simple-button/SimpleButton";
import plusWhite from "../../../assets/plus.svg";
import moveUp from "../../../assets/move_up.svg";
import moveDown from "../../../assets/move_down.svg";
import editIconBlack from '../../../assets/pretty_edit_black.svg';

export default function ProposalPackage({
                                            pkg, className = '', locked = false,
                                            handleMoveItem
                                        }) {
    const {
        setPackages
    } = useContext(PackageContext);

    const [editingTitle, setEditingTitle] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const updateTitleBackend = useCallback((title) => {
        packageApi.updatePackage(pkg.id, {title})
        setPackages(prev => prev.map(p => {
            if (p.id === pkg.id) {
                return {...p, title: title}
            }
            return p
        }))
    }, [pkg.id]);

    const _updateTitle = useMemo(() => debounce(updateTitleBackend, 800), [updateTitleBackend]);

    const updateTitle = useCallback((msg) => {
        _updateTitle(msg)
        setLocalTitle(msg)
    }, [_updateTitle]);

    // Description
    const [editingDescription, setEditingDescription] = useState(false);
    const [localDescription, setLocalDescription] = useState('');
    const updateDescriptionBackend = useCallback((updated_msg) => {
        packageApi.updatePackage(pkg.id, {description: updated_msg})
        setPackages(prev => prev.map(p => {
            if (p.id === pkg.id) {
                return {...p, description: updated_msg}
            }
            return p
        }))
    }, [pkg.id]);

    const _updateDescription = useMemo(() => debounce(updateDescriptionBackend, 800), [updateDescriptionBackend]);

    const updateDescription = useCallback((msg) => {
        _updateDescription(msg)
        setLocalDescription(msg)
    }, [_updateDescription]);

    // hide options
    const [showOptions, setShowOptions] = useState(false);

    return (<div key={pkg.id} className={`proposal-package ${className}`}>
        <div className='package-header'>
            {!editingTitle && <h2 className={locked ? '' : 'editable'} onClick={_ => {
                if (locked) return;
                setEditingTitle(true);
                setLocalTitle(pkg.title)
            }}>{pkg.proposal_order + 1}. {pkg.title} {pkg.title.length === 0 && <label className='empty'> Afsnit</label>} <img src={editIconBlack} alt='edit title'/></h2>}
            {editingTitle && <div>
                <SimpleInput
                    startWithFocus
                    value={localTitle}
                    onChanged={updateTitle}
                    onBlur={_ => setEditingTitle(false)}
                />
            </div>}

            <div className='grower'/>
            {pkg.proposal_order !== 0 && <SimpleButton
                className={'move-btn up'}
                just_text
                icon={moveUp}
                onClick={_ => handleMoveItem(pkg.id, 'up')}
            />}
            <SimpleButton
                className={'move-btn down'}
                just_text
                icon={moveDown}
                onClick={_ => handleMoveItem(pkg.id, 'down')}
            />
        </div>
        <section className='package-details'>
            <div className={`package-description-wrapper ${locked ? '' : 'editable'}`}>
                {!editingDescription && <p onClick={_ => {
                    if (locked) return;
                    setEditingDescription(true);
                    setLocalDescription(pkg.description)
                }} className={`package-description `}>
                    {pkg.description}{pkg.description.length === 0 && <label className='empty'>Beskrivelse</label>}
                </p>}
                <img src={editIconBlack} alt='edit description' onClick={_ => {
                    if (locked) return;
                    setEditingDescription(true);
                    setLocalDescription(pkg.description)
                }}/>
            </div>
            {editingDescription && <div>
                <SimpleTextarea
                    startWithFocus
                    value={localDescription}
                    onChanged={updateDescription}
                    onBlur={_ => setEditingDescription(false)}
                />
            </div>}

            <hr/>

            {(showOptions || pkg.options.length > 0) && <div className='horizontal option-header'>
                <h2>Valgmuligheder</h2>
                <div className='grower'/>
                <SimpleInput
                    options={['Pr pax', 'Flad rate']}
                    onlySelectableOptions
                    disabled={locked}
                    onChanged={e => {
                        const translated = {
                            'Pr pax': 'px', 'Flad rate': 'flat',
                        }[e];
                        packageApi.updatePackage(pkg.id, {price_type: translated})
                            .then(() => {
                                setPackages(prev => prev.map(p => {
                                    if (p.id === pkg.id) {
                                        return {...p, price_type: translated}
                                    }
                                    return p
                                }))
                            })
                    }}
                    value={{
                        'px': 'Pr pax', 'flat': 'Flad rate'
                    }[pkg.price_type]}
                />
            </div>}
            <div className='package-options'>
                {pkg.options.map((opt, idx) => <ProposalOption locked={locked} pkg={pkg} key={opt.id} opt={opt}/>)}
            </div>
            <div className='horizontal'>
                <SimpleButton
                    icon={plusWhite}
                    value='Tilføj valgmulighed'
                    className='add-option-btn'
                    onClick={_ => {
                        packageApi.createOptionForPackage(pkg.id, '', '', 0, 0).then(opt => {
                            setPackages(prev => prev.map(p => {
                                if (p.id === pkg.id) {
                                    return {...p, options: [...p.options, opt]}
                                }
                                return p
                            }))
                        })
                    }}
                />
                <div className='grower'/>
                <div className='delete-btn-wrapper'>
                    <SimpleButton
                        just_text
                        icon={bin}
                        value='Slet sektion'
                        onClick={_ => {
                            if (window.confirm('Er du sikker på at du vil slette denne sektion?')) {
                                packageApi.deletePackage(pkg.id).then(_ => {
                                    setPackages(prev => prev.filter(p => p.id !== pkg.id));
                                });
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    </div>)
}
