import React, {useCallback, useEffect, useState} from "react";
import SimpleButton from "../../component/simple-button/SimpleButton";
import './landing-page.css';
import LinkButton from "../../component/link-button/LinkButton";
import splash1 from '../../assets/landing-page/graphic_tech_setup.jpeg';
import handsShaking from '../../assets/landing-page/hands-shaking.jpg';
import puzzleHands from '../../assets/landing-page/hands-puzzle.jpg';
import {marketingApi} from "../../api";
import addGuests from './img/add-guests-icon.svg';
import importGuests from './img/importer-guest-icon.svg';
import createRoomIcon from './img/opret-lokaler-icon.svg';
import salCreation from './img/salsopsætning-icon.svg';
import {useNavigate} from "react-router-dom";

export default function LandingPage() {
    const [contactInfo, setContactInfo] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        loading: false,
        weWillContactYou: false,
    })

    const sendContactInfo = useCallback(() => {
        setContactInfo(p => ({...p, loading: true}));
        marketingApi.contact_me(contactInfo).then(() => {
            setContactInfo(p => ({
                loading: false,
                weWillContactYou: true,
            }));
        })
    }, [contactInfo]);

    const emailSubscription = useCallback(() => {
        console.log(contactInfo)
    }, [contactInfo]);


    const navigate = useNavigate();
    useEffect(() => {
        navigate('/login')
    }, [navigate]);

    return (
        <main className='landing-page'>
            <div className='header'>
                <h1 className='title'>Open<span>seat.</span></h1>
                <LinkButton href='/app'>App</LinkButton>
            </div>
            <section>
                <div>
                    <h1 className='title'>Open<span>seat.</span></h1>
                    <h2>Din ultimative partner til effektiv eventplanlægning og gæstehåndtering</h2>
                    <LinkButton href={'#contact-me'}>Hør mere</LinkButton>
                </div>
                <p>
                    Openseat er en intuitiv softwareløsning, designet til at gøre planlægningen og afviklingen af
                    dine events til en leg, selv når du skal håndtere et stort antal gæster.
                </p>
                {/*<img src={splash1} alt='Bordplan' style={{maxWidth: '550px'}}/>*/}
            </section>

            <section className='horizontal'>
                <div>
                    <hr className='small-divider'/>
                    <h3>Oprette og organisere gæstelister med lethed</h3>
                    <p>
                        Vores brugervenlige interface gør det nemt at lave gæstelister, så du kan fokusere på at
                        skabe en mindeværdig oplevelse for dine gæster.
                    </p>
                </div>
                <div>
                    <hr className='small-divider'/>
                    <h3>Designe bordplaner fleksibelt</h3>
                    <p>
                        Med vores omfattende datahåndtering kan du fleksibelt designe bordplaner,
                        der passer til dit events unikke behov.
                    </p>
                </div>
                <div>
                    <hr className='small-divider'/>
                    <h3>Sikre effektiv kommunikation</h3>
                    <p>
                        Openseat sikrer effektiv kommunikation mellem alle involverede parter,
                        fra arrangører til gæster, så alle er på samme side.
                    </p>
                </div>
            </section>

            {false && <section className='flow-chart'>
                <div>
                    <div className='text-wrapper'>
                        <h2>Opret lokaler</h2>
                        <h3>Angiv deres mål og form.</h3>
                    </div>
                    <img style={{maxHeight: '250px'}} src={createRoomIcon}
                         alt='opret lokaler'/>
                </div>

                <div>
                    <div className='text-wrapper'>
                        <h2>Salopsætning</h2>
                        <h3>Brug den intuitive drag-and-drop funktion til at oprette en salopsætning, der passer til
                            dit events behov.</h3>
                    </div>
                    <img style={{maxHeight: '250px'}} src={salCreation} alt='salsopsætning'/>
                </div>

                <div>
                    <div className=''>
                        <h2>Importer din gæsteliste</h2>
                        <h3>Direkte fra Excel eller inviter gæster via e-mail.</h3>
                    </div>
                    <img style={{maxHeight: '250px'}} src={importGuests} alt='importer gæster'/>
                </div>

                <div>
                    <div className='text-wrapper'>
                        <h2>Lav nemt en bordplan</h2>
                        <h3>Drag-and-drop gæster fra din gæsteliste til dine borde - eller lad din kunde stå for
                            denne del af processen.</h3>
                    </div>
                    <img style={{maxHeight: '250px'}} src={addGuests} alt='tilføj gæster'/>
                </div>
            </section>}

            <section className='flex-wrap-reverse'>
                <img src={puzzleHands} style={{width: '500px'}} alt='Bordplan'/>
                <div>
                    <h2>Hvordan kommer vi i gang?</h2>
                    <p>
                        Med Openseat behøver du ikke bekymre dig om at ændre hele din organisations arbejdsgange.
                        Vi er her for at tilpasse vores løsninger, så de komplementerer og forbedrer de processer,
                        der allerede fungerer godt hos jer.
                    </p>
                    <LinkButton href={'#contact-me'}>Kontakt Os</LinkButton>
                </div>
            </section>

            <section className='testimonial'>
                <h5 className='section-header'>TESTIMONIAL</h5>
                <h3>"Vi er yderst tilfredse med seatingsystemet Openseat, som er et uundværligt værktøj i forbindelse
                    med planlægning af Cirkusbygningens mange events. Vi har sparet mange timer og sikrer nu, at
                    der ikke sker fejl i forbindelse med seating af vores gæster. Dette er til stor gavn for
                    organisationen internt og for vores kunder, der roser værktøjets brugervenlighed.
                    <br/><br/>
                    Jeg giver Openseat mine varmeste anbefalinger.."</h3>
                <h2>Marlene Sønderhaug, Head of Event, Cirkusbygningen</h2>
            </section>

            <section className='block-color who-we-are'>
                <div className='heading-text'>
                    <h3>Hvem er vi?</h3>
                    <p>
                        Openseat blev skabt ud fra et behov identificeret i Cirkusbygningen. Processen med at håndtere
                        bordplaner var en udfordring for alle involverede - fra kunder og gæster til køkkenpersonalet og
                        eventkoordinatorer. Der var et klart behov for et effektivt værktøj, der kunne strømline
                        gæsteplaceringen og samtidig sikre effektiv kommunikation.
                    </p>
                    <p>
                        Fætrene Bjørn og Victor, ikke bare familie, men også gode venner, har altid haft et øje for at
                        identificere uløste problemer og omdanne dem til forretningsmuligheder. Denne gang så de en
                        chance for at bruge deres unikke færdigheder til at skabe en løsning, der adresserer
                        kompleksiteten ved eventhåndtering, der påvirker forskellige interessenter - hvad enten det er
                        private personer eller virksomheder, der afholder events, steder, der leverer plads, eller
                        gæster, der deltager i disse events.</p>
                </div>

                <div className='horizontal flex-wrap-reverse'>
                    <div>
                        <h3>Bjørn Høiby-kaae</h3>
                        <p>
                            Bjørn har en baggrund som datalog og full-stack-udvikler. I de sidste 10 år har Bjørn stået
                            i spidsen for udviklingen af omfattende IT-løsninger i forskellige startups og scale-ups.
                        </p>
                        <p>
                            Bjørn har en særlig interesse for den enkle og intuitive løsning, der effektivt løser et
                            komplekst behov.
                        </p>
                    </div>
                    <div>
                        <h3>Victor Juul</h3>
                        <p>
                            Victor har tilbragt de seneste 15 år i hospitalitybranchen, heraf de sidste knap 3 år i
                            rollen som salgschef i Cirkusbygningen, hvor han har solgt og produceret store events.
                        </p>
                        <p>
                            Victor har en særlig interesse for processer, adfærdsdesign, kommunikation og den træning,
                            der skaber effektiv afvikling af events, der giver en god kundeoplevelse - og dermed en god
                            økonomi.
                        </p>
                    </div>
                </div>
            </section>

            <section className='contact' id='contact-me'>
                <div>
                    <h2>Hør fra os</h2>
                    <p>
                        Vil du høre mere om hvordan Openseat kunne passe ind hos dig? Udfyld formularen og bliv
                        inviteret til en uforpligtende snak og analyse.
                    </p>
                </div>
                {contactInfo.weWillContactYou && <div>
                    <h2>Tak for din henvendelse</h2>
                    <p>Vi vil kontakte dig hurtigst muligt!</p>
                    <p>Hvis det ikke går hurtigt nok, så skriv til Victor på <a
                        href='mailto:victor@openseat.dk'>victor@openseat.dk</a></p>
                </div>}
                {!contactInfo.weWillContactYou && <form onSubmit={sendContactInfo}>
                    <input type='text' placeholder='Navn' value={contactInfo.name}
                           onChange={e => setContactInfo(p => ({
                               ...p, 'name': e.target.value
                           }))}/>
                    <input type='email' placeholder='Email' value={contactInfo.email}
                           onChange={e => setContactInfo(p => ({
                               ...p, 'email': e.target.value
                           }))}/>
                    <input type='phone' placeholder='Telefon' value={contactInfo.phone}
                           onChange={e => setContactInfo(p => ({
                               ...p, 'phone': e.target.value
                           }))}/>
                    <textarea placeholder='Besked' value={contactInfo.message}
                              onChange={e => setContactInfo(p => ({
                                  ...p, 'message': e.target.value
                              }))}/>
                    <SimpleButton value='Send' onClick={sendContactInfo} loading={contactInfo.loading}/>
                </form>}
            </section>

            {/*<section className='contact'>*/}
            {/*    <h1>Tilmeld dig nyhedsbrevet</h1>*/}
            {/*    <p>*/}
            {/*        Vi sender sjælendt mails, men når vi gør er det for at informerer om nye spændende features.*/}
            {/*        Tilmeld*/}
            {/*        dig vores nyhedsbrev for altid at være med på de seneste opdateringer og tilbud.*/}
            {/*    </p>*/}
            {/*    <form>*/}
            {/*        <input type='email' placeholder='Email'/>*/}
            {/*        <br/>*/}
            {/*        <SimpleButton value='Tilmeld'/>*/}
            {/*    </form>*/}
            {/*</section>*/}

            <footer>
                <h5>OpenSeat</h5>
                <p>© 2024 OpenSeat</p>
            </footer>
        </main>
    );
}
