import React, {useEffect, useMemo, useState} from "react";
import SimpleInput from "../../component/simple-input/SimpleInput";
import {useSelector} from "react-redux";
import {selectVenues} from "../../slice/venueSlice";
import {economyApi, EventApi, packageApi, VenueAPI} from "../../api";
import {
    BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LineChart, Line,
} from "recharts";
import {toDanishDate} from "../../component/date_functions";
import './economic-overview.css';

export default function EconomicOverviewPage() {
    function formatPrice(number) {
        return number.toLocaleString('da-DK', {minimumFractionDigits: 0}) + ',-';
    }

    const [allEvents, setAllEvents] = useState([]);
    const [packages, setPackages] = useState([]);
    const [customers, setCustomers] = useState([]);

    const venues = useSelector(selectVenues);

    // filters
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);

    useEffect(() => {
        if (!venues) return;
        Promise.all(venues.map(venue => VenueAPI.list_venue_events(venue.id))).then(events => {
            setAllEvents(events.flat());
        })
    }, [venues]);

    const [rawData, setRawData] = useState([]);
    useEffect(() => {
        economyApi.get_event_specific_cost().then(setRawData);
    }, []);

    const totalGroupedEvent = useMemo(() => {
        if (!rawData || !allEvents) return {};
        const lookup = {};
        rawData.forEach(d => {
            if (!lookup[d.event_id]) {
                lookup[d.event_id] = 0;
            }
            lookup[d.event_id] += d.total_price;
        });
        return lookup;
    }, [rawData, allEvents]);

    function aggregateDataByDateAndStatus(data) {
        const lookup = {};

        data.forEach(d => {
            const key = new Date(d.date).getMonth() + 1 + '/' + new Date(d.date).getFullYear();
            if (!lookup[key]) {
                lookup[key] = {date: key, proposal: 0, won: 0};
            }
            lookup[key][d.sale_status] += d.total_price;
        });

        return Object.values(lookup).sort((a, b) => new Date(a.date) - new Date(b.date));
    }

    return (<section className='economic-overview-page'>
        <h1>Økonomisk overblik</h1>
        {false && <div className='horizontal'>
            <SimpleInput
                title="Venue"
                options={[...venues.map(v => v.name), 'Alle']}
                canSelectMultiple
                onlySelectableOptions
                onChanged={setSelectedVenues}
                value={selectedVenues}
            />
            <SimpleInput
                title="Pakker"
                options={[...packages.map(v => v.title), 'Alle']}
                canSelectMultiple
                onlySelectableOptions
                onChanged={setSelectedPackages}
                value={selectedPackages}
            />
            <SimpleInput
                title="Kunder"
                options={[...customers.map(v => v.company_name), 'Alle']}
                canSelectMultiple
                onlySelectableOptions
                onChanged={setSelectedCustomers}
                value={selectedCustomers}
            />
        </div>}

        <div style={{margin: "20px"}}>
            <h2>Salgs status pr måned</h2>
            <BarChart width={600} height={300} data={aggregateDataByDateAndStatus(rawData)}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="proposal" stackId="stack1" fill="#8884d8"/>
                <Bar dataKey="won" stackId="stack2" fill="#82ca9d"/>
            </BarChart>
        </div>
        <table className='open-proposals'>
            <thead>
            <tr>
                <th>Event</th>
                <th>Kundenavn</th>
                <th>Sælger</th>
                <th>Status</th>
                <th>Tilbudsdeadline</th>
                <th>Total beløb</th>
            </tr>
            </thead>
            <tbody>
            {allEvents.filter(e => e.sale_status === 'proposal').map(e => <tr key={e.id}>
                <td>{e.title}</td>
                <td>{e.customer?.company_name}</td>
                <td>{}</td>
                <td>{e.sale_status}</td>
                <td>{e.proposal_deadline ? toDanishDate(new Date(e.proposal_deadline)) : ''}</td>
                <td>{formatPrice(totalGroupedEvent[e.id] ?? 0)}</td>
            </tr>)}
            </tbody>
        </table>
    </section>);
}
