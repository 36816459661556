import React, {useCallback, useEffect, useRef, useState} from "react";
import './single-char-button.css'
import arrow from "../../assets/arr_down_white.svg";

export default function SingleCharButton({char, onClick, options, className}) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = useCallback(() => {
        if (options) {
            console.log('toggle');
            setIsOpen(p => !p);
            return;
        }
        console.log('click');
        onClick(char);
    }, [char, onClick, options]);

    const handleSelect = (value) => {
        onClick(value);
        setIsOpen(false);
    };

    useEffect(() => {
        if (!isOpen) return;
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                console.log('click outside');
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    return (<div className={`single-char-button ${className ?? ''}`} ref={dropdownRef}>
        <div className={`char-div ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
            <span>
                {char}
            </span>
        </div>
        {isOpen && options && (<div className="menu">
            {options.map(opt => (<div
                key={opt}
                className='option'
                onClick={() => handleSelect(opt)}>
                {opt}
            </div>))}
        </div>)}
    </div>)
}
