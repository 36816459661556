import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {customerApi, packageApi, proposalApi} from "../../../../api";
import ProposalPackage from "../../../../component/package-components/proposal-package/ProposalPackage";
import {useDispatch, useSelector} from "react-redux";
import {
    selectEventById, selectLocallyUpdatedEvent
} from "../../../../slice/eventSlice";
import {PackageContext} from "../../../../component/package-components/package-parent/PackageParentPage";
import SimpleInput from "../../../../component/simple-input/SimpleInput";
import SimpleButton from "../../../../component/simple-button/SimpleButton";
import EconomyTable from "../../../../component/package-components/economy-table/EconomyTable";
import NewPackagePopout from "../../../../component/package-components/new-package-popout/NewPackagePopout";
import './proposal-page.css';
import {toDanishDate} from "../../../../component/date_functions";
import html2pdf from "html2pdf.js";
import CenteredPopup from "../../../../component/centered-popup/CenteredPopup";
import bin from "../../../../assets/bin_v2.svg";
import back_with_text from "../../../../assets/back_with_text.svg";
import pdfIcon from "../../../../assets/pdf_icon_white.svg";
import pdfIconBlack from "../../../../assets/pdf_icon_black.svg";
import SimpleCard from "../../../../component/simple-card/SimpleCard";
import plusBlack from "../../../../assets/plus_black.svg";
import ClientProposalPage from "../../../client/proposal/ClientProposalPage";
import economyIcon from '../../../../assets/economy_icon.svg';
import checkmarkGreen from '../../../../assets/checkmark_green.svg';
import warningTriangle from '../../../../assets/warning_triangle.svg';

export default function ProposalPage({proposal_id: prop_proposal_id, setProposals, backClicked = null}) {
    const {proposal_id: urlPropId, event_id, venue_id} = useParams();

    const updatedEvent = useSelector(selectLocallyUpdatedEvent)
    const currentEvent = useSelector(selectEventById(event_id));

    const proposal_id = useMemo(() => prop_proposal_id || urlPropId, [prop_proposal_id, urlPropId]);

    const {
        setPackagePopoutStatus, dragging, setDragging, packages, setPackages, activeProposal, setActiveProposal
    } = useContext(PackageContext);

    const proposalRef = useRef(null);
    const dispatch = useDispatch();

    const [scrollDirection, setScrollDirection] = useState(0);
    const scrollIntervalRef = useRef(null);

    // economy
    const [economyPopout, setEconomyPopout] = useState(false);
    const [guestCountExample, setGuestCountExample] = useState(150);

    // display export popup
    const [showExportPopup, setShowExportPopup] = useState(false);

    // customer
    const [customer, setCustomer] = useState(null);

    // token
    const [token, setToken] = useState(null);

    const [generatedPdfSignedUrl, setGeneratedPdfSignedUrl] = useState(null);

    useEffect(() => {
        if (!proposal_id) {
            return;
        }
        proposalApi.getProposalById(proposal_id).then(act => {
            setActiveProposal(act);
        });
        packageApi.listPackagesForProposal(proposal_id).then(packs => {
            setPackages(packs.sort((c1, c2) => c1.proposal_order - c2.proposal_order));
        });
        proposalApi.getProposalToken(proposal_id).then(setToken).catch(_ => {
        });
    }, [proposal_id, setActiveProposal, setPackages]);

    useEffect(() => {
        if (!activeProposal) {
            return;
        }
        packageApi.listPackagesForProposal(activeProposal.id).then(setPackages);
        if (activeProposal.locked) {
            proposalApi.getProposalToken(activeProposal.id).then(setToken);
            proposalApi.getProposalPdfSignedUrl(activeProposal.id).then(setGeneratedPdfSignedUrl);
        }
    }, [activeProposal, setPackages]);

    useEffect(() => {
        if (!currentEvent) {
            return;
        }
        customerApi.getCustomer(currentEvent.customer_id).then(setCustomer);
    }, [currentEvent]);

    useEffect(() => {
        if (scrollDirection) {
            scrollIntervalRef.current = setInterval(() => {
                // Adjust scroll speed/direction to your liking:
                document.querySelector('#venue-page-scrollable').scrollBy(0, 50 * scrollDirection);
            }, 50);
        } else {
            clearInterval(scrollIntervalRef.current);
        }
        return () => clearInterval(scrollIntervalRef.current);
    }, [scrollDirection]);

    const handleMoveItem = useCallback((item_id, direction) => {
        const newOrder = [...packages];
        const idx = newOrder.findIndex(pkg => pkg.id === item_id);
        if (idx === -1) return;

        let newIdx = direction === 'up' ? idx - 1 : idx + 1;
        if (newIdx < 0) newIdx = 0;
        if (newIdx >= newOrder.length) newIdx = newOrder.length - 1;

        const [moved] = newOrder.splice(idx, 1);
        newOrder.splice(newIdx, 0, moved);

        const packageNewOrderMap = {};
        newOrder.forEach((item, i) => {
            packageNewOrderMap[item.id] = i;
        });

        setPackages(prev => prev.map(pkg => ({
            ...pkg, proposal_order: packageNewOrderMap[pkg.id]
        })));

        if (Object.keys(packageNewOrderMap).length > 0) {
            packageApi.batchUpdatePackageOrders(venue_id, event_id, packageNewOrderMap);
        }
    }, [packages, setPackages, event_id, venue_id]);

    const insertedNewPackage = useCallback((pkg) => {
        // handle order after inserting package
        const new_order = [...packages.filter(p => p.type !== 'economy')];

        const packageNewOrderMap = {};
        new_order.filter(p => p.proposal_order >= pkg.proposal_order).forEach(item => {
            packageNewOrderMap[item.id] = item.proposal_order + 1;
        });
        setPackages(packs => packs.map(p => {
            return {...p, proposal_order: packageNewOrderMap[p.id] ?? p.proposal_order}
        }))
        if (Object.keys(packageNewOrderMap).length > 0) {
            packageApi.batchUpdatePackageOrders(venue_id, event_id, packageNewOrderMap);
        }
    }, [packages, setPackages]);

    const exportToPDF = () => {
        const element = proposalRef.current;

        // Add print class to make some custom styling
        element.classList.add('printable-wrapper');

        const options = {
            margin: 0.2,
            filename: 'proposal.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'a4', orientation: 'portrait'}
        };
        html2pdf().set(options).from(element).toContainer().save();
        element.classList.remove('printable-wrapper');
    };

    const generatePdfAndDisplay = () => {
        const element = proposalRef.current;
        // Add print class to make some custom styling
        element.classList.add('printable-wrapper');
        const options = {
            margin: 0.2,
            filename: 'proposal.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'a4', orientation: 'portrait'},
        };

        // Instead of saving the PDF, we output a data URI string
        html2pdf().set(options).from(element).output('datauristring').then((pdfDataUri) => {
            // Open a popup or new window and embed the PDF in an iframe
            proposalApi.uploadProposalPdf(activeProposal.id, pdfDataUri).then(res => {
                console.log("Uploaded PDF:", res);
                setGeneratedPdfSignedUrl(res);
            });
        })
            .catch((err) => {
                console.error("Error generating PDF:", err);
            })
            .finally(() => {
                element.classList.remove('printable-wrapper');
            });
    };

    if (!packages) {
        return <div>Loading packs...</div>
    }

    if (!activeProposal) {
        return <div>Loading proposal...</div>
    }

    return <div className={`proposal-page ${activeProposal.locked ? 'locked-proposal' : ''}`}>
        <div className='document-template-header'>
            <SimpleButton
                icon={back_with_text}
                just_text
                className='back-btn hide-in-print'
                onClick={_ => {
                    if (backClicked) {
                        backClicked();
                        return;
                    }
                    if (event_id) {
                        window.location.href = `/app/venue/${venue_id}/event/${event_id}/home`;
                    } else {
                        window.location.href = `/app/venue/${venue_id}/dokument-template`;
                    }
                }}/>
            <div className='grower'/>
            <SimpleButton
                just_text
                value='Slet dokument'
                icon={bin}
                onClick={_ => {
                    if (window.confirm('Er du sikker på at du vil slette dette dokument?')) {
                        proposalApi.deleteProposal(proposal_id).then(_ => {
                            setProposals(prev => prev.filter(p => p.id !== proposal_id));
                            if (event_id) {
                                window.location.href = `/app/venue/${venue_id}/event/${event_id}/home`;
                            } else {
                                window.location.href = `/app/venue/${venue_id}/dokument-template`;
                            }
                        });
                    }
                }}
            />
            {event_id && <SimpleButton
                value='Håndter dokument adgang'
                secondary
                onClick={_ => setShowExportPopup(true)}/>}

            {/*{event_id && activeProposal.locked === 1 && <SimpleButton*/}
            {/*    icon={pdfIcon}*/}
            {/*    value='Download som PDF'*/}
            {/*    onClick={_ => exportToPDF()}*/}
            {/*/>}*/}

            {/*{event_id && activeProposal.locked === 1 && <SimpleButton*/}
            {/*    icon={pdfIcon}*/}
            {/*    value='Lås op og rediger'*/}
            {/*    onClick={_ => {*/}
            {/*        proposalApi.createDraftFromProposal(proposal_id).then(res => {*/}
            {/*            setActiveProposal(res);*/}
            {/*        });*/}
            {/*    }}*/}
            {/*/>}*/}
        </div>

        {/*{activeProposal.locked === 1 && generatedPdfSignedUrl && <ClientProposalPage _token={}/> }*/}
        {activeProposal.locked === 1 && token && <ClientProposalPage _token={token} propProposalRef={proposalRef}/>}

        {activeProposal.locked === 0 && <>
            <h1 className='title'><img src={pdfIconBlack}/> {activeProposal.name}</h1>

            <div ref={proposalRef} className='proposal-main'>
                {currentEvent && <div className='info-cards'>
                    <SimpleCard title='Event' text={currentEvent ? currentEvent.title : 'Event navn'}/>
                    <SimpleCard title='Dato' text={currentEvent ? currentEvent.date : 'Dato'}/>
                    <SimpleCard title='Tid'
                                text={currentEvent ? `${currentEvent.timestamp_from}-${currentEvent.timestamp_to}` : 'Tidspunkt'}/>
                    <SimpleCard title='Antal gæster'
                                text={currentEvent ? currentEvent.expected_guests : 'Antal gæster'}/>

                    {<p className='only-show-in-print'><b>Tilbudsdeadline:</b>{activeProposal.proposal_deadline}
                    </p>}
                </div>}

                <div className='item-wrapper'>
                    <SimpleButton
                        just_text
                        icon={plusBlack}
                        value='Tilføj sektion'
                        className='add-new-package'
                        onClick={sel => {
                            packageApi.createPackage('', null, null, proposal_id, 'multiple', 'flat', '', 0)
                                .then(pkg => {
                                    setPackages(prev => [...prev, pkg]);
                                    insertedNewPackage(pkg);
                                })
                        }}
                    />
                </div>

                {packages
                    .filter(p => p.type !== 'economy')
                    .sort((c1, c2) => {
                        const f = c1.proposal_order - c2.proposal_order
                        if (f !== 0) return f;
                        return c2.id - c1.id;
                    }).map(pkg => [<div className='item-wrapper' key={`section-${pkg.id}-${pkg.frontend_type}`}>
                        <ProposalPackage
                            pkg={pkg}
                            locked={activeProposal.locked}
                            key={`item package-${pkg.id}`}
                            className='item-content'
                            event_package={pkg}
                            setEventPackages={setPackages}
                            handleMoveItem={handleMoveItem}
                        />
                        <SimpleButton
                            just_text
                            icon={plusBlack}
                            value='Tilføj sektion'
                            className='add-new-package'
                            onClick={_ => {
                                packageApi.createPackage('', null, null, proposal_id, 'multiple', 'flat', '', pkg.proposal_order + 1)
                                    .then(pkg => {
                                        setPackages(prev => [...prev, pkg]);
                                        insertedNewPackage(pkg);
                                    })
                            }}
                        />
                    </div>])}
                {dragging && <>
                    <div className='dragging-overlay top'
                         onDragEnter={_ => setScrollDirection(-1)}
                         onDragLeave={_ => setScrollDirection(0)}>&nbsp;</div>

                    <div className='dragging-overlay bottom'
                         onDragEnter={_ => setScrollDirection(1)}
                         onDragLeave={_ => setScrollDirection(0)}>&nbsp;</div>
                </>}
                <div className='item-wrapper'>
                    <div className='horizontal center economy-header'>
                        <h3><img src={economyIcon} alt='economy'/> Økonomi</h3>
                        {!event_id && <SimpleInput
                            title='Antal gæster eksempel'
                            type='number'
                            value={guestCountExample}
                            onChanged={e => setGuestCountExample(e)}
                        />}
                    </div>
                    <EconomyTable packages={packages}
                                  expected_guests={guestCountExample}
                    />
                    {economyPopout && <NewPackagePopout
                        title='Tilføjer økonomi post'
                        button_text='Tilføj post'
                        type='economy'
                        venue_id={event_id ? null : venue_id}
                        event_id={event_id}
                        proposal_id={activeProposal ? activeProposal.id : proposal_id}
                        onClose={_ => setEconomyPopout(false)}
                        createdNewPackage={pkg => setPackages(prev => [...prev, pkg])}
                    />}
                </div>
            </div>
        </>}

        <CenteredPopup showPopup={showExportPopup} closePopup={setShowExportPopup} className='export-popup'>
            <h2>Tilbudsadgang</h2>
            {activeProposal.locked === 0 && <>
                <h3 className='proposal-not-locked-header'><img src={warningTriangle} />For at afsende, skal du låse dette tilbud</h3>
                <p>
                    Før du kan sende tilbuddet, skal det være <b>låst</b>, så ingen kan foretage ændringer. Først når den er
                    låst - kan den sendes. Hvis du senere vil lave ændringer i tilbuddet, skal du lave en nyt udkast.
                </p>
                <p>
                    Sæt en deadline for seneste svar fra kunden, hvor de senest skal have godkendt eller afvist tilbuddet.
                </p>
            </>}
            {activeProposal.locked === 1 && <>
                <h3 className='proposal-locked-header'><img src={checkmarkGreen} alt='locked and ready to send'/>Tilbuddet
                    er låst og kan nu sendes til kunden.</h3>

                <p>
                    Tilbuddet er afsluttet og kan nu sendes til kunden. Du skal blot kopiere nedestående link og sende
                    en e-mail til kunden. Alternativt kan du downloade tilbuddet som en PDF og sende det.
                </p>
                <p>
                    Husk at sætte status på dokumentet til "Sendt til kunde" når det er afsendt, så du kan holde
                    overblikket.
                </p>
            </>}

            <b>Obs! Denne feature er i BETA og er ikke klar til at blive taget i brug endnu. Snak med Victor eller Alexander,
            hvis du gerne vil høre mere om at sende dokumenter til underskrift når denne feature er klar.</b>
            {activeProposal.locked === 1 && token && <SimpleInput
                title='Send dette link til kunden'
                type='text'
                className='copyaple-link'
                value={`https://openseat.dk/proposal/${token}`}
                readOnly
            />}

            <div className='horizontal'>
                {activeProposal.locked === 1 && <SimpleInput
                    title='Status'
                    onlySelectableOptions
                    options={['Afsendt', 'Godkendt', 'Afvist']}
                    value={activeProposal.status[0].toUpperCase() + activeProposal.status.slice(1)}
                    onChanged={e => {
                        setActiveProposal({...activeProposal, status: e.toLowerCase()});
                    }}
                />}
                <div className='grower'/>
                <SimpleInput
                    title='Tilbudsdeadline'
                    type='date'
                    className='hide-in-print'
                    disabled={activeProposal.locked}
                    value={toDanishDate(activeProposal.proposal_deadline ?? '')}
                    onChanged={e => {
                        setActiveProposal({...activeProposal, proposal_deadline: e});
                    }}
                />
            </div>

            <hr/>
            {activeProposal.locked === 1 &&
                <div className='horizontal center'>
                    <div className='grower'/>
                    <SimpleButton
                        value='Nyt udkast'
                        secondary
                        onClick={_ => {
                            proposalApi.createDraftFromProposal(proposal_id).then(res => {
                                setActiveProposal(res);
                                setShowExportPopup(false);
                            });
                        }}
                    />
                    <SimpleButton
                        value='Download'
                        icon={pdfIcon}
                        onClick={_ => exportToPDF()}
                    />
                </div>
            }

            {activeProposal.locked === 0 &&
                <div className='horizontal center'>
                    <div className='grower'/>
                    <div>
                        <SimpleButton
                            value='Lås tilbud'
                            disabled={!activeProposal.proposal_deadline}
                            onClick={_ => {
                                proposalApi.generateTokenAndLock(proposal_id, toDanishDate(activeProposal.proposal_deadline)).then(res => {
                                    const {proposal, token} = res;

                                    setActiveProposal(proposal);
                                    setToken(token);
                                    setProposals(prev => prev.map(p => p.id === proposal.id ? proposal : p));
                                });
                            }}
                        />
                    </div>
                </div>
            }
        </CenteredPopup>
    </div>
}
