import React, {useMemo} from "react";
import './economy-table.css';

export default function EconomyTable({packages, expected_guests, show_variable, show_netto=false}) {
    function formatPrice(number) {
        return number.toLocaleString('da-DK', { minimumFractionDigits: 0 }) + ' kr';
    }

    const {total, netto} = useMemo(() => {
        const economy = packages.filter(p => p.type === 'economy');

        const economyOpts = economy.map(p => p.options).flat();
        let netto = economyOpts.reduce((acc, p) => acc + parseInt(p.netto_amount), 0);
        let total = economyOpts.reduce((acc, p) => acc + parseInt(p.total_amount), 0);

        packages.forEach(p => {
            const priceType = p.price_type;
            const selected = p.options.filter(o => o.selected);

            const optTotal = selected.reduce((acc, o) => acc + parseInt(o.total_amount), 0);
            const optNetto = selected.reduce((acc, o) => acc + parseInt(o.netto_amount), 0);
            if (priceType === 'px') {
                total += optTotal * expected_guests;
                netto += optNetto * expected_guests;
            } else {
                total += optTotal;
                netto += optNetto;
            }
        })

        return {total, netto};
    }, [packages, expected_guests]);

    return (<div className='economy-table-wrapper'>
        <table className='economy-table'>
            <thead>
            <tr>
                <th>Post</th>
                <th>Antal</th>
                <th>Pris</th>
                <th>Total</th>
            </tr>
            </thead>
            <tbody>
            {packages.filter(e => e.type === 'economy').map(pkg => <tr key={pkg.id}>
                <td>{pkg.title}</td>

                {pkg.options.map(opt => ([
                        show_netto ? <td key={`${opt.id}-netto`}>{opt.netto_amount},-</td> : null,
                        <td key={`${opt.id}-asd`}>1</td>,
                        <td key={`${opt.id}-unitprice`}>{formatPrice(opt.total_amount)}</td>,
                        <td key={`${opt.id}-total`}>{formatPrice(opt.total_amount)}</td>
                    ]
                ))}
            </tr>)}

            {!show_variable && packages.filter(e => e.type !== 'economy').map(event_package => {
                const selected = event_package.options.filter(opt => opt.selected);

                if (selected.length === 0) {
                    return null;
                }

                let unitPrice;
                let itemCount;
                let total;
                if (selected.length === 0) {
                    unitPrice = 0;
                    itemCount = 0;
                    total = 0;
                } else {
                    unitPrice = selected.reduce((acc, opt) => acc + parseInt(opt.total_amount), 0);
                    itemCount = event_package.price_type === 'px' ? expected_guests : 1;
                    total = unitPrice * itemCount;
                }

                return (<tr key={event_package.id}>
                    <td>{event_package.title}</td>
                    <td>{itemCount}</td>
                    <td>{formatPrice(unitPrice)}</td>
                    <td>{formatPrice(total)}</td>
                </tr>)
            })}

            <tr className='total'>
                <th colSpan={3}>Total</th>
                {show_netto && <td>{netto},-</td>}
                <td><b>{formatPrice(total)}</b></td>
            </tr>

            {show_variable && <tr>
                <th colSpan={3}>Kundevalg</th>
            </tr>}

            {show_variable && packages.filter(e => e.type !== 'economy').map(event_package => {
                const minNetto = Math.min(...event_package.options.map(opt => parseInt(opt.netto_amount)));
                const maxNetto = Math.max(...event_package.options.map(opt => parseInt(opt.netto_amount)));

                const minTotal = Math.min(...event_package.options.map(opt => parseInt(opt.total_amount)));
                const maxTotal = Math.max(...event_package.options.map(opt => parseInt(opt.total_amount)));

                const isPax = event_package.price_type === 'px';

                const guestCount = isPax ? 1 : expected_guests;

                return (<tr key={event_package.id}>
                    <td>{event_package.title}</td>
                    {show_netto && <td>({minNetto * guestCount} - {maxNetto * guestCount}),-</td>}
                    <td>({minTotal * guestCount} - {maxTotal * guestCount}),-</td>
                </tr>)
            })}
            </tbody>
        </table>
    </div>)
}
