import React, {useCallback} from 'react';
import checkmark from '../../assets/checkmark.svg';
import './simple-checkbox.css';


export default function SimpleCheckbox({ checked, onChange, title, disabled, className, ...props }) {
    const handleClick = useCallback(() => {
        if (!disabled && onChange) {
            onChange(!checked);
        }
    }, [checked, disabled, onChange]);

    return (
        <div
            {...props}
            className={`simple-checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${className ? className : ''}`}
            onClick={handleClick}>
            <div className="square">
                <img src={checkmark} alt="checkmark" />
            </div>
            {title && <span className="custom-checkbox-label">{title}</span>}
        </div>
    );
}
